import { createStore } from 'vuex'
import auth from './modules/auth.js'
import editor from './modules/editor.js'
import project from './modules/project.js'
import projectAutopopulate from './modules/projectAutopopulate.js'
import projectList from './modules/projectList.js'
import organization from './modules/organization'
import subscriptions from './modules/subscriptions.js'
import ui from './modules/ui.js'
import userInfo from './modules/userInfo.js'
import users from './modules/users.js'
import preferences from './modules/preferences.js'
import maintenanceNotifications from './modules/maintenance-notification.js'

export default createStore({
    modules: {
      auth,
      editor,
      project,
      projectAutopopulate,
      projectList,
      organization,
      subscriptions,
      ui,
      userInfo,
      users,
      preferences,
      maintenanceNotifications,
    },

    state:{

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    },
})
