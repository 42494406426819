<template>
  <div v-if="isUnderMaintenance">
    <div id="documill-main-section" uk-height-viewport="expand: true">
      <UnderMaintenance
        :message="serviceMaintenanceMessage"
        :endDate="serviceMaintenanceEndDateISO"/>
    </div>
  </div>

  <div v-else>
    <Analytics/>
    <!--
      TODO: If there is a need for other "messages/alerts" like this, consider creating a custom
            component for showing them.
    -->
    <div v-if="showSubscriptionExpiredBar" class="subscription-expired-bar uk-text-center uk-width-1-1 uk-padding-small">
      <span v-html="$t('app.no_active_subscription')" />
    </div>
    <nav v-if="showNavBar" class="uk-navbar-container uk-padding uk-padding-remove-vertical " uk-navbar>
      <div class="uk-navbar-left">
        <a v-if="showHamburgerMenu" class="uk-navbar-toggle uk-hidden@m" uk-toggle="target: #sidenav"  uk-navbar-toggle-icon></a>
        <router-link custom v-slot="{ navigate }"
            :to="{name:'home',params:this.organizationParameter}">
          <a @click="navigate" @keypress.enter="navigate" role="link"
            id="main-logo"
            class="uk-navbar-item uk-logo"
            :class="{ 'uk-disabled' : !$can('access', 'home'), 'uk-visible@m' : showHamburgerMenu }"
            :disabled="!$can('access', 'home')"
            :event="$can('access', 'home') ? 'click' : ''">
            <img src="./assets/img/documill-logo-32x32.svg" style="width: 32px;" alt="Documill logo" uk-img>
          </a>
        </router-link>

        <ul class="uk-navbar-nav uk-visible@m uk-margin-large-left documill-header">
          <router-link v-if="$can('access', 'home')" custom v-slot="{ href, navigate, isActive, isExactActive }" :to="{name:'home',params:this.organizationParameter}">
            <li data-v-step="home-tab" :class="[isActive && 'uk-active', isExactActive && 'router-link-exact-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.home") }}
              </a>
            </li>
          </router-link>
          <router-link v-if="$can('access', 'projects')" custom v-slot="{ href, navigate, isActive }" :to="{name:'projects',params:this.organizationParameter}">
            <li data-v-step="projects-tab" :class="[isActive && 'uk-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.projects") }}
              </a>
            </li>
          </router-link>
          <router-link v-if="$can('access', 'templates')" custom v-slot="{ href, navigate, isActive }" :to="{name:'templates',params:this.organizationParameter}">
            <li data-v-step="templates-tab" :class="[isActive && 'uk-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.workflows") }}
              </a>
            </li>
          </router-link>
          <router-link v-if="$can('access', 'users')" custom v-slot="{ href, navigate, isActive, isExactActive }" :to="{name:'users',params:this.organizationParameter}">
            <li data-v-step="users-tab" :class="[isActive && 'uk-active', isExactActive && 'router-link-exact-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.users") }}
              </a>
            </li>
          </router-link>
          <router-link  v-if="$can('access', 'organization')" custom v-slot="{ href, navigate, isActive, isExactActive }" :to="{name:'organization',params:this.organizationParameter}">
            <li data-v-step="organization-tab" :class="[isActive && 'uk-active', isExactActive && 'router-link-exact-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.organization") }}
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="uk-navbar-right uk-flex">
        <UserNavbarInfo v-on:open-quick-start="isQuickStartOpen = true" data-v-step="user-nav-bar" />
        <HelpMenu v-on:open-quick-start="isQuickStartOpen = true"/>
      </div>
    </nav>

    <!-- FIXME: Why the same structure is copypasted below? Refactor it so the same can be reused. -->

    <!-- still doesn't work -->
    <!-- it works after adding uk-offcanvas="overlay: true" -->
    <div id="sidenav" v-if="showNavBar" uk-offcanvas="mode: push; overlay: true">
      <div class="uk-offcanvas-bar">
        <ul class="uk-nav uk-navbar-nav uk-tab-left uk-tab">
          <router-link v-if="$can('access', 'home')" custom v-slot="{ href, navigate, isActive, isExactActive }" :to="{name:'home',params:this.organizationParameter}">
            <li :class="[isActive && 'uk-active', isExactActive && 'router-link-exact-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.home") }}
              </a>
            </li>
          </router-link>
          <router-link v-if="$can('access', 'projects')" custom v-slot="{ href, navigate, isActive }" :to="{name:'projects',params:this.organizationParameter}">
            <li :class="[isActive && 'uk-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">{{ $t("app.nav.projects") }}</a>
            </li>
          </router-link>
          <router-link v-if="$can('access', 'templates')" custom v-slot="{ href, navigate, isActive }" :to="{name:'templates',params:this.organizationParameter}">
            <li :class="[isActive && 'uk-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.workflows") }}
              </a>
            </li>
          </router-link>
          <router-link v-if="$can('access', 'users')" custom v-slot="{ href, navigate, isActive, isExactActive }" :to="{name:'users',params:this.organizationParameter}" >
            <li :class="[isActive && 'uk-active', isExactActive && 'router-link-exact-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.users") }}
              </a>
            </li>
          </router-link>
          <router-link v-if="$can('access', 'organization')" custom v-slot="{ href, navigate, isActive, isExactActive }" :to="{name:'organization',params:this.organizationParameter}">
            <li :class="[isActive && 'uk-active', isExactActive && 'router-link-exact-active']">
              <a :draggable="false" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ $t("app.nav.organization") }}
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </div>

    <div v-if="showComponent" id="documill-main-section" uk-height-viewport="expand: true">
      <!-- This is where all the page components are shown -->
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <!-- Floating Quick Start window -->
    <QuickStartWindow :isOpen="isQuickStartOpen"
    v-if="isQuickStartOpen"
    v-on:floating-window-closed="isQuickStartOpen = false"/>

    <!-- Version poller component for checking UI version. See DOS-1691. -->
    <VersionPoller />
  </div>
</template>

<script>
/**
 * The root Vue application of Flow Service.
 *
 * @author Documill
 */

import $ from 'jquery';
import UIkit from 'uikit';
import { DateTime } from 'luxon';

import Icons from 'uikit/dist/js/uikit-icons';

import Commons from './utils/commons';

import Analytics from './views/components/analytics/Analytics';
import HelpMenu from './views/components/HelpMenu';
import QuickStartWindow from './views/components/QuickStartWindow';
import UserNavbarInfo from './views/components/UserNavbarInfo';
import VersionPoller from './views/components/VersionPoller';
import LocalStorage from './utils/localStorage';
import UnderMaintenance from './views/pages/UnderMaintenance';

// Import minified or non-minified UIKit CSS depending on whether we're in production or development mode
require(UIKIT_CSS);

UIkit.use(Icons);

//Make below imports global
window.UIkit = UIkit; // UIKit
window.$ = $; // JQuery

document.title="Documill Leap";

export default {
  name: 'App',

  components: {
    Analytics,
    HelpMenu,
    QuickStartWindow,
    UserNavbarInfo,
    VersionPoller,
    UnderMaintenance
  },

  data: function () {
    return {
      isQuickStartOpen: false,
      showComponent: true,
      isUnderMaintenance: Commons.isUnderMaintenance(),
      maintenanceCheckId: null
    }
  },

  /**
   * Local variables that needs to be computed first
   */
  computed: {
    /**
     * Boolean to define if user is authenticated or not.
     * Returns true of 'Token' exist.
     */
    authenticated: function() {
      return this.$store.getters.isAuthenticated;
    },

    isOrganizationSubscriptionExpired: function() {
      return this.$store.getters.isSubscriptionExpired;
    },

    isUIBusy: function() {
      return this.$store.getters['ui/isBusy'];
    },

    organizationParameter: function(){
      return {organizationId: this.$store.getters.organizationId};
    },

    userLocale: function() {
      return this.$store.getters.getUserLocale;
    },

    showHamburgerMenu: function() {
      // Note: Do not show the "hamburger-menu", if the user does not have access to any of the
      //       page links contained by it and the menu would therefore be empty. See FLOW-4037.

      return this.$can('access', 'home') ||
             this.$can('access', 'projects') ||
             this.$can('access', 'templates') ||
             this.$can('access', 'users') ||
             this.$can('access', 'organization');
    },

    showNavBar: function() {
      return this.authenticated && this.routeRequiresAuthentication;
    },

    /**
     * Almost all routes require authentication, with the exception of
     * Log in, Sign up, and Shared Task.
     */
    routeRequiresAuthentication: function() {
      return !this.isUnderMaintenance &&
             this.$route.name != 'OrganizationPicker' &&
             this.$route.name != 'taskShareView' &&
             this.$route.name != 'FindOrganizations' &&
             this.$route.name != 'signup' &&
             this.$route.name != 'login';
    },

    showSubscriptionExpiredBar: function() {
      return this.authenticated &&
             this.$route.name != 'OrganizationPicker' &&
             this.$route.name != 'taskShareView' &&
             this.isOrganizationSubscriptionExpired;
    },

    serviceMaintenance: function() {
      return window.FlowWebsiteGlobalConfig.serviceMaintenance;
    },

    serviceMaintenanceEndDateISO: function() {
      return this.serviceMaintenance && this.serviceMaintenance.endDate ?
        this.serviceMaintenance.endDate : null;
    },

    serviceMaintenanceMessage: function() {
      if(!this.serviceMaintenance)
        return null;

      if(typeof this.serviceMaintenance === 'string')
        return this.serviceMaintenance;

      return this.serviceMaintenance.message || null;
    },

    /**
     * Service maintenance start date if it's scheduled to begin within a reasonable time limit.
     *
     * @return {DateTime} service maintenance start date-time
     */
    serviceMaintenanceStartDate: function() {
      if(this.isUnderMaintenance || !this.serviceMaintenance || !this.serviceMaintenance.startDate)
        return null;

      const currentDate = DateTime.now();
      let startDateObj = DateTime.fromISO(this.serviceMaintenance.startDate);

      return ( // Within the next 24 hours?
        startDateObj.isValid &&
        startDateObj.diff(currentDate).days < 1 &&
        currentDate.valueOf() < startDateObj.valueOf()
      ) ? startDateObj : null;
    },
  },

  watch: {

    // When user switches organizations or the service maintenance has started force a component
    // reload. See DOS-1674, FLOW-3892.
    $route(to, from) {
      if((to.params.organizationId && from.params.organizationId &&
          from.params.organizationId != to.params.organizationId) ||
         (!this.isUnderMaintenance && Commons.isUnderMaintenance())) {

        this.isUnderMaintenance = Commons.isUnderMaintenance();
        this.showComponent = false;

        location.reload();
      }
      else {
        this.showComponent = true;
      }
    },

    userLocale(locale) {
      if(locale) {
        this.$i18n.locale = locale; // Change locale. See DOS-1892.
        // Update locale for Flatpickr. See DOS-1951.
        // The locale is required in the format 'xx' (e.g. 'fi', 'de').
        this.$flatpickr.localize(this.$flatpickrLanguages[locale]);
      }
    }
  },

  methods: {
    /**
     * Force a reload if the page was loaded from bfcache
     * when a user clicked browser's back button. The page will be reloaded
     * if it requires authentication but a user is logged out.
     *
     * More info: https://web.dev/articles/bfcache
     */
    reloadPage: function(event) {
      if (event.persisted && this.routeRequiresAuthentication && !LocalStorage.getUserToken()) {
        window.location.reload();
      }
      else if(this.routeRequiresAuthentication &&
        !LocalStorage.getUserToken() &&
        this.$route.params.organizationId &&
        LocalStorage.getUserOrganizations() &&
        JSON.parse(LocalStorage.getUserOrganizations()).activeOrgId.length == 0) {
          this.$router.push({name: 'login'});
      }
      else if(this.routeRequiresAuthentication &&
        this.$route.params.organizationId &&
        LocalStorage.getUserOrganizations() &&
        JSON.parse(LocalStorage.getUserOrganizations()).activeOrgId != this.$route.params.organizationId){
          this.$router.push({name: 'home', params: { organizationId: JSON.parse(LocalStorage.getUserOrganizations()).activeOrgId }, query: {reload: true}  });
      }
    },

    scheduleMaintenanceCheck: function() {
      this.clearMaintenanceCheck();

      let startDate = this.serviceMaintenanceStartDate;

      if(startDate != null) {
        this.maintenanceCheckId = window.setTimeout(() => {
          this.maintenanceCheckId = null;
          this.isUnderMaintenance = Commons.isUnderMaintenance();
        },startDate.diffNow().toMillis());
      }
    },

    clearMaintenanceCheck: function() {
      if(this.maintenanceCheckId != null) {
        window.clearTimeout(this.maintenanceCheckId);
        this.maintenanceCheckId = null;
      }
    },
  },

  created: function() {
    // Event listeners to reload a page if it is shown from bfcache (see DOS-3628)
    window.addEventListener('pageshow', this.reloadPage);
    window.addEventListener('visibilitychange', this.reloadPage);
  },

  mounted: function() {
    // When mounting the App set the Vuex state User Locale as i18n locale to actually enable
    // the locale. This is needed because $i18n loses the locale information when e.g. a page
    // is refreshed.

    if(this.userLocale) {
      this.$i18n.locale = this.userLocale;
      // DOS-1951: Set locale for flatpickr.
      this.$flatpickr.localize(this.$flatpickrLanguages[this.userLocale]);
    }

    this.scheduleMaintenanceCheck();
  },

  beforeUnmount() {
    window.removeEventListener('pageshow', this.reloadPage);
    window.removeEventListener('visibilitychange', this.reloadPage);

    this.clearMaintenanceCheck();
  },
}

</script>

<style>
@import "./documill.css";

@media only screen and (max-width: 400px) {
  .uk-modal-footer .uk-button{
    margin-right: 15px;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.subscription-expired-bar {
  background-color: #ffecab;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}

#documill-main-section {
  /* height:1px is necessary to ensure that the child component can have height: 100%*/
  height: 1px;
}
</style>
