/**
 * Vue Store related to current user preferences information.
 *
 * @author Documill
 */

import apiCall from '../../utils/api';
import { logger } from '../../utils/logger';
import Notification from "../../utils/notification";

export default {

  state: {
    emailNotifications: true,
    emailNotificationsReason: null,
    slackIntegration: false,
    salesforceIntegration: false,
    salesforceChatter: false,
    msTeamsIntegration: false,
    salesforceGroupId: null,
    preferencesLoaded: false,
    preferencesSaveRetries: 0,
    preferencesLoadRetries: 0,
    userNotificationPreferences: null,
    userPONotificationPreferences: null
  },

  getters: {

    /**
     * Checks if email notification enabled.
     *
     * How to use:
     * this.$store.getters.emailNotificationsEnabled
     */
    emailNotificationsEnabled: state => state.emailNotifications,

    /**
     * Gets email notification state reason, if any.
     *
     * How to use:
     * this.$store.getters.emailNotificationsReason
     */
    emailNotificationsReason: state => state.emailNotificationsReason,

    /**
     * Checks if the Salesforce integration enabled.
     *
     * How to use:
     * this.$store.getters.salesforceIntegrationEnabled
     */
    salesforceIntegrationEnabled: state => state.salesforceIntegration,

    /**
     * Gets the current Salesforce chatter group identity.
     *
     * How to use:
     * this.$store.getters.getSalesforceGroupId
     */
    getSalesforceGroupId: state => state.salesforceGroupId,

    /**
     * Checks if the Salesforce chatter notification enabled.
     *
     * How to use:
     * this.$store.getters.salesforceChatterEnabled
     */
    salesforceChatterEnabled: state => state.salesforceChatter,

    /**
     * Checks if the Slack notifications enabled.
     *
     * How to use:
     * this.$store.getters.slackIntegrationEnabled
     */
    slackIntegrationEnabled: state => state.slackIntegration,

    /**
     * Checks if the Ms Teams notifications enabled.
     *
     * How to use:
     * this.$store.getters.msTeamsIntegrationEnabled
     */
    msTeamsIntegrationEnabled: state => state.msTeamsIntegration,

    /**
     * Checks if user preferences are loaded.
     *
     * How to use:
     * this.$store.getters.preferencesLoaded
     */
    preferencesLoaded: state => state.preferencesLoaded,

    /**
     * Gets user preferences loading retries.
     *
     * How to use:
     * this.$store.getters.getPreferencesLoadRetries
     */
    getPreferencesLoadRetries: state => state.preferencesLoadRetries,

    /**
     * Gets user preferences saving retries.
     *
     * How to use:
     * this.$store.getters.getPreferencesSaveRetries
     */
    getPreferencesSaveRetries: state => state.preferencesSaveRetries,

    /**
     * Gets user preferences.
     *
     * How to use:
     * this.$store.getters.getUserPreferences
     */
    getUserPreferences: (state) => {
      let preferences = {
        emailNotifications: state.emailNotifications,
        emailNotificationsReason: state.emailNotificationsReason,
        slackIntegration: state.slackIntegration,
        salesforceIntegration: state.salesforceIntegration,
        msTeamsIntegration: state.msTeamsIntegration,
        salesforceGroupId: state.salesforceGroupId,
        salesforceChatter: state.salesforceChatter,
        userNotificationPreferences: state.userNotificationPreferences,
        userPONotificationPreferences: state.userPONotificationPreferences
      };
      return preferences;
    },
  },

  mutations: {

    /**
     * Sets user preferences to state.
     *
     * @param {*} state
     * @param {*} preferences data object
     */
    SET_ALL_PREFERENCES(state, preferences) {
      state.emailNotifications = preferences.emailNotifications;
      state.emailNotificationsReason = preferences.emailNotificationsReason;
      state.slackIntegration = preferences.slackIntegration;
      state.salesforceIntegration = preferences.salesforceIntegration;
      state.salesforceChatter = preferences.salesforceChatter;
      state.msTeamsIntegration = preferences.msTeamsIntegration;
      state.salesforceGroupId = preferences.salesforceGroupId;

      // Notification properties. See DOS-2434.

      state.userNotificationPreferences = preferences.userNotificationPreferences;
      state.userPONotificationPreferences = preferences.userPONotificationPreferences;
    },

    /**
     * Sets email notifications setting to state.
     *
     * @param {*} state
     * @param {*} value boolean (enable/disable)
     */
    SET_EMAIL_NOTIFICATIONS(state, value) {
      state.emailNotifications = value;
    },

    /**
     * Sets a single user/collaborator notification preference to state.
     *
     * Expected parameters:
     * {
     *   name: string (preference name/key),
     *   value: string (preference value)
     * }
     *
     * @param {*} state
     * @param {*} params parameters
     */
    SET_NOTIFICATIONS_PREFERENCE(state, params) {
      if(state.userNotificationPreferences)
        state.userNotificationPreferences[params.name] = params.value;
    },

    /**
     * Sets a single project owner notification preference to state.
     *
     * Expected parameters:
     * {
     *   name: string (preference name/key),
     *   value: string (preference value)
     * }
     *
     * @param {*} state
     * @param {*} params parameters
     */
    SET_PO_NOTIFICATIONS_PREFERENCE(state, params) {
      if(state.userPONotificationPreferences)
        state.userPONotificationPreferences[params.name] = params.value;
    },

    /**
     * Sets Slack integration setting to state.
     *
     * @param {*} state
     * @param {*} value boolean (enable/disable)
     */
    SET_SLACK_INTEGRATION(state, value) {
      state.slackIntegration = value;
    },

    /**
     * Sets Salesforce integration setting to state.
     *
     * @param {*} state
     * @param {*} value boolean (enable/disable)
     */
    SET_SALESFORCE_INTEGRATION(state, value) {
      state.salesforceIntegration = value;
    },

    /**
     * Sets Salesforce chatter setting to state.
     *
     * @param {*} state
     * @param {*} value boolean (enable/disable)
     */
    SET_SALESFORCE_CHATTER(state, value) {
      state.salesforceChatter = value;
    },

    /**
     * Sets Current Salesforce chatter group to state.
     *
     * @param {*} state
     * @param {*} value string with group identity
     */
    SET_SALESFORCE_GROUP_ID(state, value) {
      state.salesforceGroupId = value;
    },

    /**
     * Sets Ms Teams integration settings to state.
     *
     * @param {*} state
     * @param {*} value boolean (enable/disable)
     */
    SET_MSTEAMS_INTEGRATION(state, value) {
      state.msTeamsIntegration = value;
    },

    /**
     * Sets user preferences loading status to state.
     *
     * @param {*} state
     * @param {*} value boolean (loaded/not loaded)
     */
    SET_PREFERENCES_LOADING_STATE(state, loaded) {
      state.preferencesLoaded = loaded;
    },

    /**
     * Sets user preferences loading retries 0 to state.
     *
     * @param {*} state
     */
    RESET_PREFERENCES_LOAD_RETRIES(state) {
      state.preferencesLoadRetries = 0;
    },

    /**
     * Sets user preferences saving retries 0 to state.
     *
     * @param {*} state
     */
    RESET_PREFERENCES_SAVE_RETRIES(state) {
      state.preferencesSaveRetries = 0;
    },

    /**
     * Increments by 1 user preferences loading retries at state.
     *
     * @param {*} state
     */
    INCREMENT_PREFERENCES_LOAD_RETRIES(state) {
      state.preferencesLoadRetries++;
    },

    /**
     * Increments by 1 user preferences saving retries at state.
     *
     * @param {*} state
     */
    INCREMENT_PREFERENCES_SAVE_RETRIES(state) {
      state.preferencesSaveRetries++;
    },
  },

  actions: {

    /**
     * Sets Email notification setting,
     * updates preferences at backend.
     *
     * How to use:
     * this.$store.dispatch("setEmailNotifications", value);
     * @param {boolean} value
     */
    setEmailNotifications(context, value){
      // emailNotificationsReason is automatically set by backend, and presumably updated automatically soon enough
      if(value === true)
      {
        UIkit.notification("Email notification enabled.",
            {status:Notification.STATUS.PRIMARY});
      }
      else
      {
        UIkit.notification("Email notification disabled.",
            {status:Notification.STATUS.PRIMARY});
      }
      context.commit("SET_EMAIL_NOTIFICATIONS", value);
      this.dispatch("savePreferences");
    },

    /**
     * Sets a single user/collaborator notification preference and updates both state and back-end.
     *
     * Expected parameters:
     * {
     *   name: string (preference name),
     *   value: string (preference value)
     * }
     *
     * How to use:
     * this.$store.dispatch("setUserNotificationPreference", params);
     *
     * @param {*} params parameters
     *
     * @returns {Promise} promise
     */
    setUserNotificationPreference(context,params) {
      context.commit("SET_NOTIFICATIONS_PREFERENCE",params);
      return this.dispatch("saveUserNotificationPreferences");
    },

    /**
     * Sets a single PO notification preference and updates both state and back-end.
     *
     * Expected parameters:
     * {
     *   name: string (preference name),
     *   value: string (preference value)
     * }
     *
     * How to use:
     * this.$store.dispatch("setUserPONotificationPreference", params);
     *
     * @param {*} params parameters
     *
     * @returns {Promise} promise
     */
    setUserPONotificationPreference(context,params) {
      context.commit("SET_PO_NOTIFICATIONS_PREFERENCE",params);
      return this.dispatch("saveUserPONotificationPreferences");
    },

    /**
     * Sets Slack notification setting,
     * updates preferences at backend.
     *
     * How to use:
     * this.$store.dispatch("setSlackIntegration", value);
     * @param {boolean} value
     */
    setSlackIntegration(context, value){
      context.commit("SET_SLACK_INTEGRATION", value);
      this.dispatch("savePreferences");
    },

    /**
     * Sets Salesforce notification setting,
     * updates preferences at backend.
     *
     * How to use:
     * this.$store.dispatch("setSalesforceIntegration", value);
     * @param {boolean} value
     */
    setSalesforceIntegration(context, value){
      context.commit("SET_SALESFORCE_INTEGRATION", value);
      this.dispatch("savePreferences");
    },

    /**
     * Sets Salesforce chatter group identity setting,
     * updates preferences at backend.
     *
     * How to use:
     * this.$store.dispatch("setSalesforceGroupId", value);
     * @param {boolean} value
     */
    setSalesforceGroupId(context, value){
      context.commit("SET_SALESFORCE_GROUP_ID", value);
      this.dispatch("savePreferences");
    },

    /**
     * Sets Salesforce chatter notification setting,
     * updates preferences at backend.
     *
     * How to use:
     * this.$store.dispatch("setSalesforceChatter", value);
     * @param {boolean} value
     */
    setSalesforceChatter(context, value){
      if(value === true)
      {
        UIkit.notification("Salesforce Chatter notifications enabled.",
            {status:Notification.STATUS.PRIMARY});
      }
      else
      {
        UIkit.notification("Salesforce Chatter notifications disabled.",
            {status:Notification.STATUS.PRIMARY});
      }
      context.commit("SET_SALESFORCE_CHATTER", value);
      this.dispatch("savePreferences");
    },

    /**
     * Sets Ms Teams notification setting,
     * updates preferences at backend.
     *
     * How to use:
     * this.$store.dispatch("setMsTeamsIntegration", value);
     * @param {boolean} value
     */
    setMsTeamsIntegration(context, value){
      context.commit("SET_MSTEAMS_INTEGRATION", value);
      this.dispatch("savePreferences");
    },

    /**
     * Saves user's current collaborator/user notification preferences to back-end.
     *
     * How to use:
     * this.$store.dispatch("saveUserNotificationPreferences");
     *
     * @returns {Promise} promise
     */
    async saveUserNotificationPreferences(context) {
      let preferences = this.getters.getUserPreferences;
      let notificationPreferences = preferences.userNotificationPreferences;

      if(notificationPreferences == null) {
        logger.error("No notification preferences exist!");
        return;
      }

      try {
        const result = await apiCall.put('v1/preferences/update-user-notifications',
                                         notificationPreferences);
        logger.debug("saveUserNotificationPreferences succeeded with result:", result);
        // Note: Returns a basic UserPreferences API response.
        context.commit("SET_ALL_PREFERENCES", result.data);
        return result;
      }
      catch(error) {
        logger.error("saveUserNotificationPreferences didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Saves user's current PO notification preferences to back-end.
     *
     * How to use:
     * this.$store.dispatch("saveUserPONotificationPreferences");
     *
     * @returns {Promise} promise
     */
    async saveUserPONotificationPreferences(context) {
      let preferences = this.getters.getUserPreferences;
      let notificationPreferences = preferences.userPONotificationPreferences;

      if(notificationPreferences == null) {
        logger.error("No notification preferences exist!");
        return;
      }

      try {
        const result = await apiCall.put('v1/preferences/update-po-notifications',
                                         notificationPreferences);
        logger.debug("saveUserPONotificationPreferences succeeded with result:", result);
        // Note: Returns a basic UserPreferences API response.
        context.commit("SET_ALL_PREFERENCES", result.data);
        return result;
      }
      catch(error) {
        logger.error("saveUserPONotificationPreferences didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Saves preferences to database.
     *
     * How to use:
     * this.$store.dispatch("savePreferences");
     */
    savePreferences(context){
      if(this.getters.preferencesLoaded === false) {
        if(this.getters.getPreferencesSaveRetries <= 5)
        {
          context.commit("INCREMENT_PREFERENCES_SAVE_RETRIES");
          setTimeout(() => {context.dispatch("savePreferences")}, 1000);
        }
      }
      else {
        let data = this.getters.getUserPreferences;
        apiCall.put("v1/preferences/update", data).then(response => {
          context.commit("SET_ALL_PREFERENCES", response.data);
          context.commit("RESET_PREFERENCES_SAVE_RETRIES");
        }).catch(error => {
          console.error(
              "Query update user preferences didn't succeed with message:",
              error.response
          );
          reject(error);
        });
      }
    },

    /**
     * Loads user preferences.
     *
     * How to use:
     * this.$store.dispatch("loadUserPreferences");
     */
    loadUserPreferences(context){
      apiCall.get("v1/preferences/get").then(response => {
        context.commit("SET_ALL_PREFERENCES", response.data);
        context.commit("SET_PREFERENCES_LOADING_STATE", true);
        context.commit("RESET_PREFERENCES_LOAD_RETRIES");
      }).catch(error => {
        console.error(
            "Query get user preferences didn't succeed with message:",
            error.response
        );
        context.commit("SET_PREFERENCES_LOADING_STATE", false);
        if(this.getters.getPreferencesLoadRetries <= 5) {
          context.commit("INCREMENT_PREFERENCES_LOAD_RETRIES");
          window.setTimeout(context.dispatch("loadUserPreferences"), 1000);
        }
        else {
          UIkit.notification("Unable to load user preferences.",
              {status:Notification.STATUS.DANGER});
        }
      });
    },
  }
}