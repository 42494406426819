<!--
  Loads analytics allowed by the user, and also shows consent banner + management dialog if not configured yet.
-->
<template>
  <div id="analytics" class="uk-card uk-card-documill">
    <!-- Load only trackers that are enabled -->
    <div
      v-if="configurationCompleted"
      id="trackers"
      key="analytics-trackers"
    >
      <div v-for="tracker in trackers" :key="tracker.id">
        <!-- Load subcomponent dynamically -->
        <component
          v-if="tracker.enabled"
          :is="{...tracker.component}"
          :consentGiven="configurationCompleted && tracker.enabled"
        ></component>
      </div>
    </div>
    <!-- Banner with text and "Manage" button -->
    <div
      v-if="!configurationCompleted"
      class="uk-card-body banner"
      uk-sticky
      key="analytics-banner"
    > <!-- ^ DOS-2935: Specify 'key' to prevent Vue from reusing element and confusing UIkit -->
      <p class="disclaimer"><span v-html="$t('analytics.banner.disclaimer')" /></p>
      <div class="buttons">
        <button
          :uk-toggle="`target: #${consentScreenId}`"
          class="uk-button uk-button-documill border"
        >{{ $t("analytics.banner.manage") }}</button>
        <button
          class="uk-button uk-button-documill border"
          @click="rejectAll"
          >{{ $t("analytics.banner.reject_non_essential") }}</button>
        <button
          class="uk-button uk-button-documill border"
          @click="acceptAll"
          >{{ $t("analytics.banner.accept_all") }}</button>
      </div>
    </div>
    <!-- Consent screen behind "Manage" button -->
    <div
      :id="consentScreenId"
      class="uk-flex-top"
      uk-modal="bg-close:false;container:#analytics"
      @mousedown="onMouseDown($event,consentScreenId)">
      <div class="uk-modal-dialog uk-margin-auto-vertical uk-animation-fast uk-width-auto">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
          <span class="uk-modal-title">{{ $t("analytics.consent_screen.title") }}</span>
        </div>
        <div class="uk-modal-body">
          <!-- Required data -->
          <h2>{{ $t("analytics.consent_screen.required_data.title") }}</h2>
          <p><span v-html="$t('analytics.consent_screen.required_data.disclaimer')" /></p>
          <SwitchToggle
            :label="$t('analytics.consent_screen.required_data.label')"
            :uk-tooltip="'title: '+$t('analytics.consent_screen.required_data.label')+'; pos: bottom-right; delay: 600;'"
            :checked="true"
            :disabled="true"
            :name="'required_data'"
            >
          </SwitchToggle>
          <hr />
          <!-- List of trackers -->
          <h2>{{ $t("analytics.consent_screen.analytics.title") }}</h2>
          <p><span v-html="$t('analytics.consent_screen.analytics.disclaimer')" /></p>
          <SwitchToggle
            v-for='tracker in trackers'
            :key='tracker.id'
            :label="tracker.name"
            :uk-tooltip="'title: '+tracker.name+'; pos: bottom-right; delay: 600;'"
            v-model:checked="tracker.enabled"
            :name="tracker.id">
          </SwitchToggle>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="atLeastOneTrackerEnabled"
            class="uk-button uk-button-documill border"
            @click="setAllTrackers(false)"
            >{{ $t("analytics.consent_screen.disable_non_essential") }}</button>
          <button v-else
            class="uk-button uk-button-documill border"
            @click="setAllTrackers(true)"
            >{{ $t("analytics.consent_screen.enable_all") }}</button>
          <button
            class="uk-button uk-button-documill border"
            @click="completeConfiguration"
            >{{ $t("analytics.consent_screen.save_choices") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import GoogleAnalytics from "./GoogleAnalytics";
import SwitchToggle from "../SwitchToggle";

/**
 * Analytics component.
 *
 * This currently supports just Google Analytics.
 *
 * The analytics subcomponents are not loaded until consent is given.
 *
 * @see DOS-1871
 *
 * @author Documill
 */

export default {
  name: "Analytics",

  components: {
    SwitchToggle,
    // Analytics components are declared dynamically via :is="tracker.component"
  },

  data: function() {
    return {
      consentScreenId: "analytics-consent-screen",
      configurationCompleted: false, // Settings should not take effect until configuration is completed
      /** The trackers, and if the user has enabled them or not. */
      trackers: [
        {
          id: "google-analytics",
          name: "Google Analytics",
          component: GoogleAnalytics,
          enabled: false, // Do not enable until user consents
        },
        /*{
          id: "test-analytics",
          name: "Test Analytics",
          component: GoogleAnalytics,
          enabled: false,
        },*/
      ]
    };
  },

  computed: {
    atLeastOneTrackerEnabled: function() {
      for(let tracker of this.trackers){
        if(tracker.enabled)
          return true;
      }
      return false;
    }
  },

  created: function() {
    this.loadConfiguration();
  },

  methods: {
    onMouseDown: function(event,dialogId) {
      if(event.target.id == dialogId)
        this.closeDialog(event.target);
    },

    /** Closes given UIkit modal dialog.
     *
     * @params dialog either HTML element or CSS selector
     */

    closeDialog(dialog) {
      UIkit.modal(dialog).hide();
    },

    rejectAll() {
      this.setAllTrackers(false);
      this.completeConfiguration();
    },

    acceptAll() {
      this.setAllTrackers(true);
      this.completeConfiguration();
    },

    setAllTrackers(enabled) {
      for(let tracker of this.trackers){
        tracker.enabled = enabled;
      }
    },

    completeConfiguration() {
      this.configurationCompleted = true;
      this.closeDialog("#"+this.consentScreenId); // TODO: Consider passing dialog fetched via ref for modularity

      // Store configuration for later visits
      this.saveConfiguration();
    },

    /**
     * Saves configuration to local storage for later visits.
     */
    saveConfiguration() {
      let configuration = {};

      for(let tracker of this.trackers) {
        configuration[tracker.id] = {
          id: tracker.id,
          enabled: tracker.enabled
        };
      }

      localStorage.setItem("leap-analytics-configuration",
                           JSON.stringify(configuration));
    },

    /**
     * Loads configuration from local storage.
     *
     * If some tracker defined in this.trackers does not have stored configuration, the
     * consent screen is shown again.
     */
    loadConfiguration() {
      let configuration = localStorage.getItem("leap-analytics-configuration");

      if(!configuration)
        configuration = {};
      else
        configuration = JSON.parse(configuration);

      // Configure trackers
      // If configuration settings for a tracker is missing, assume it is a new one
      // and show the banner again

      let allConfigured = true;

      for(let tracker of this.trackers) {
        let trackerConfiguration = configuration[tracker.id];

        if(!trackerConfiguration) {
          allConfigured = false;
          continue;
        }

        tracker.enabled = trackerConfiguration.enabled;
      }

      if(allConfigured)
        this.configurationCompleted = true; // Found stored settings for all trackers, no need to show banner
    },
  },
}

</script>

<style scoped>
  /* Disclaimer banner */

  .banner {
    background-color: #f2f2f2;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
  }

  .banner .disclaimer {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .banner .buttons {
    display: inline-block;
  }

  /* Consent dialog */

  .uk-modal p {
    max-width: 400px;
  }

  .uk-modal * + h2 {
    margin-top: 15px; /* Reduce margin for other than first h2 */
  }

  .uk-modal-body {
    padding-bottom: 40px; /* Distance buttons from above section a bit more. */
  }
</style>