/**
 * Collection of collaborate/step role functions and constants.
 */

import i18n from './../i18n';

// --- Constants ---

/**
 * Enumeration of available collaborator types.
 *
 * Note: This should match the back-end "CollaboratorType" enumeration.
 */
const TYPES = Object.freeze({
  USER: "USER",
  GUEST: "GUEST",
  TASK_USER: "TASK_USER",
  TASK_GUEST: "TASK_GUEST",
  BOT: "BOT",
});

/**
 * Enumeration of available collaborator roles for tasks.
 *
 * Note: This should match the back-end "TaskRole" enumeration.
 */
const ROLES = Object.freeze({
  APPROVER: "APPROVER",
  APPROVING_EDITOR: "APPROVING_EDITOR",
  COMMENTER: "COMMENTER",
  EDITOR: "EDITOR",
  //EXTERNAL_READER: "EXTERNAL_READER",
  INTERNAL_READER: "INTERNAL_READER",
  REVIEWER: "REVIEWER",
  SIGNER: "SIGNER",
  SIGNING_CONFIGURATOR: "SIGNING_CONFIGURATOR",
  NONE: "NONE",
  EXTERNAL_APPROVING_EDITOR: "EXTERNAL_APPROVING_EDITOR",
  EXTERNAL_COMMENTER: "EXTERNAL_COMMENTER",
  EXTERNAL_EDITOR: "EXTERNAL_EDITOR",
  EXTERNAL_REVIEWER: "EXTERNAL_REVIEWER",
  COMBINER: "COMBINER",
  OFFLINE_EDITOR: "OFFLINE_EDITOR",
  VALIDATION_REVIEWER: "VALIDATION_REVIEWER"
});


const PROJECT_ROLES = Object.freeze({
  PROJECT_OWNER: "PROJECT_OWNER",
  COLLABORATOR: "COLLABORATOR",
  LIMITED_COLLABORATOR: "LIMITED_COLLABORATOR",
  TEAM_LEADER: "TEAM_LEADER",
  INACTIVE: "INACTIVE",
});

// --- Public functions ---

/**
 * Maps a task role to a UI friendly string. These labels are to be used as titles for
 * Collaborators Status list/table. See DOS-3157.
 *
 * @param role collaborator role (may be null)
 *
 * @returns {String} UI friendly title (may be null, implies unsupported role)
 */

function roleToCollaboratorsStatusTitle(role) {
  switch(role) {
    case ROLES.APPROVING_EDITOR:
    case ROLES.EXTERNAL_APPROVING_EDITOR:
    case ROLES.EDITOR:
    case ROLES.EXTERNAL_EDITOR:
    case ROLES.OFFLINE_EDITOR:
    case ROLES.COMMENTER:
    case ROLES.EXTERNAL_COMMENTER:
      return i18n.global.t("project_tasks.tab.overview.collaborators_status_table.title.editors");

    case ROLES.INTERNAL_READER:
      return i18n.global.t("project_tasks.tab.overview.collaborators_status_table.title.recipients");

    case ROLES.REVIEWER:
    case ROLES.EXTERNAL_REVIEWER:
      return i18n.global.t("project_tasks.tab.overview.collaborators_status_table.title.reviewers");

    case ROLES.APPROVER:
      return i18n.global.t("project_tasks.tab.overview.collaborators_status_table.title.approvers");

    case ROLES.SIGNER:
      return i18n.global.t("project_tasks.tab.overview.collaborators_status_table.title.signers");

    // Unsupported:
    case ROLES.COMBINER:
    case ROLES.NONE:
    case ROLES.SIGNING_CONFIGURATOR:
    default:
      return null;
  }
}

/**
 * Maps a task role to a UI friendly string. These labels are to be used when that particular
 * Task has been completed. See DOS-3157.
 *
 * @param role collaborator role (may be null)
 *
 * @returns {String} UI friendly text representation of the role
 */

function roleToCompletedLabel(role) {
  if(role == null)
    return i18n.global.t("common.unknown");

  switch(role) {
    case ROLES.INTERNAL_READER: return i18n.global.t("task.role.internal_reader.label_completed");
    //case ROLES.EXTERNAL_READER: return "Reader (limited)";
    case ROLES.COMMENTER: return i18n.global.t("task.role.commenter.label_completed");
    case ROLES.REVIEWER: return i18n.global.t("task.role.reviewer.label_completed");
    case ROLES.APPROVER: return i18n.global.t("task.role.approver.label_completed");
    case ROLES.APPROVING_EDITOR: return i18n.global.t("task.role.approving_editor.label_completed");
    case ROLES.SIGNER: return i18n.global.t("task.role.signer.label_completed");
    case ROLES.SIGNING_CONFIGURATOR: return i18n.global.t("task.role.signing_configurator.label_completed");
    case ROLES.NONE: return i18n.global.t("task.role.none.label_completed");
    case ROLES.EXTERNAL_APPROVING_EDITOR: return i18n.global.t("task.role.external_approving_editor.label_completed");
    case ROLES.EXTERNAL_COMMENTER: return i18n.global.t("task.role.external_commenter.label_completed");
    case ROLES.EXTERNAL_EDITOR: return i18n.global.t("task.role.external_editor.label_completed");
    case ROLES.EXTERNAL_REVIEWER: return i18n.global.t("task.role.external_reviewer.label_completed");
    case ROLES.COMBINER: return i18n.global.t("task.role.combiner.label_completed");
    case ROLES.OFFLINE_EDITOR: return i18n.global.t("task.role.offline_editor.label_completed");
    case ROLES.EDITOR:
    default: return i18n.global.t("task.role.editor.label_completed");
  }
}

/**
 * Maps a task role to a UI friendly string.
 *
 * @param role collaborator role (may be null)
 *
 * @returns {String} UI friendly text representation of the role
 */

function roleToText(role) {
  if(role == null)
    return i18n.global.t("common.unknown");

  switch(role) {
    case ROLES.INTERNAL_READER: return i18n.global.t("task.role.internal_reader.label");
    //case ROLES.EXTERNAL_READER: return "Reader (limited)";
    case ROLES.COMMENTER: return i18n.global.t("task.role.commenter.label");
    case ROLES.REVIEWER: return i18n.global.t("task.role.reviewer.label");
    case ROLES.APPROVER: return i18n.global.t("task.role.approver.label");
    case ROLES.APPROVING_EDITOR: return i18n.global.t("task.role.approving_editor.label");
    case ROLES.SIGNER: return i18n.global.t("task.role.signer.label");
    case ROLES.SIGNING_CONFIGURATOR: return i18n.global.t("task.role.signing_configurator.label");
    case ROLES.NONE: return i18n.global.t("task.role.none.label");
    case ROLES.EXTERNAL_APPROVING_EDITOR: return i18n.global.t("task.role.external_approving_editor.label");
    case ROLES.EXTERNAL_COMMENTER: return i18n.global.t("task.role.external_commenter.label");
    case ROLES.EXTERNAL_EDITOR: return i18n.global.t("task.role.external_editor.label");
    case ROLES.EXTERNAL_REVIEWER: return i18n.global.t("task.role.external_reviewer.label");
    case ROLES.COMBINER: return i18n.global.t("task.role.combiner.label");
    case ROLES.OFFLINE_EDITOR: return i18n.global.t("task.role.offline_editor.label");
    case ROLES.VALIDATION_REVIEWER: return i18n.global.t("task.role.validation_reviewer.label");
    case ROLES.EDITOR:
    default: return i18n.global.t("task.role.editor.label");
  }
}

/**
 * Maps a role to their default task description.
 *
 * Usage:
 *
 * In component script:
 * 1. import Collaborators from "./../../utils/collaborators";
 * 2. Collaborators.roleToTaskDescription(role)
 *
 * @param role collaborator role (may be null)
 *
 * @returns {String} Default Task description for each roles
 */

function roleToTaskDescription(role) {
  if(role == null)
    return i18n.global.t("common.unknown");

  switch(role) {
    case ROLES.INTERNAL_READER: return i18n.global.t("task.role.internal_reader.default_task_description");
    //case ROLES.EXTERNAL_READER: return "Please receive the project's deliverable file(s)";
    case ROLES.COMMENTER: return i18n.global.t("task.role.commenter.default_task_description");
    case ROLES.REVIEWER: return i18n.global.t("task.role.reviewer.default_task_description");
    case ROLES.APPROVER: return i18n.global.t("task.role.approver.default_task_description");
    case ROLES.APPROVING_EDITOR: return i18n.global.t("task.role.approving_editor.default_task_description");
    case ROLES.SIGNER: return i18n.global.t("task.role.signer.default_task_description");
    case ROLES.SIGNING_CONFIGURATOR: return i18n.global.t("task.role.signing_configurator.default_task_description");
    case ROLES.EXTERNAL_APPROVING_EDITOR: return i18n.global.t("task.role.external_approving_editor.default_task_description");
    case ROLES.EXTERNAL_COMMENTER: return i18n.global.t("task.role.external_commenter.default_task_description");
    case ROLES.EXTERNAL_EDITOR: return i18n.global.t("task.role.external_editor.default_task_description");
    case ROLES.EXTERNAL_REVIEWER: return i18n.global.t("task.role.external_reviewer.default_task_description");
    case ROLES.COMBINER: return i18n.global.t("task.role.combiner.default_task_description");
    case ROLES.OFFLINE_EDITOR: return i18n.global.t("task.role.offline_editor.default_task_description");
    case ROLES.VALIDATION_REVIEWER: return i18n.global.t("task.role.validation_reviewer.default_task_description");

    case ROLES.EDITOR:
    default: return i18n.global.t("task.role.editor.default_task_description");
  }
}

/**
 * Returns short task description according to the given user role.
 *
 * Usage:
 *
 * In component script:
 * 1. import Collaborators from "./../../utils/collaborators";
 * 2. Collaborators.roleToShortTaskDescription(role)
 *
 * @param role collaborator role (may be null)
 *
 * @returns {String} short task description
 */
function roleToShortTaskDescription(role) {
  switch(role){
    case ROLES.INTERNAL_READER: return i18n.global.t('task.role.internal_reader.short_task_description');
    case ROLES.COMMENTER: return i18n.global.t('task.role.commenter.short_task_description');
    case ROLES.REVIEWER: return i18n.global.t('task.role.reviewer.short_task_description');
    case ROLES.APPROVER: return i18n.global.t('task.role.approver.short_task_description');
    case ROLES.APPROVING_EDITOR: return i18n.global.t('task.role.approving_editor.short_task_description');
    case ROLES.SIGNER: return i18n.global.t('task.role.signer.short_task_description');
    case ROLES.SIGNING_CONFIGURATOR: return i18n.global.t('task.role.signing_configurator.short_task_description');
    case ROLES.EXTERNAL_APPROVING_EDITOR: return i18n.global.t('task.role.external_approving_editor.short_task_description');
    case ROLES.EXTERNAL_COMMENTER: return i18n.global.t('task.role.external_commenter.short_task_description');
    case ROLES.EXTERNAL_EDITOR: return i18n.global.t('task.role.external_editor.short_task_description');
    case ROLES.EXTERNAL_REVIEWER: return i18n.global.t('task.role.external_reviewer.short_task_description');
    case ROLES.COMBINER: return i18n.global.t('task.role.combiner.short_task_description');
    case ROLES.EDITOR: return i18n.global.t('task.role.editor.short_task_description');
    case ROLES.OFFLINE_EDITOR: return i18n.global.t('task.role.offline_editor.short_task_description');
    case ROLES.VALIDATION_REVIEWER: return i18n.global.t('task.role.validation_reviewer.short_task_description');

    default: return i18n.global.t('task.role.none.short_task_description');
  }
}

function projectRoleToText(role) {
  if(role == null)
  return i18n.global.t("common.unknown");

  switch(role) {
    case PROJECT_ROLES.PROJECT_OWNER: return i18n.global.t("collaborator.role.project_owner");
    case PROJECT_ROLES.COLLABORATOR: return i18n.global.t("collaborator.role.collaborator");
    case PROJECT_ROLES.LIMITED_COLLABORATOR: return i18n.global.t("collaborator.role.limited_collaborator");
    case PROJECT_ROLES.TEAM_LEADER: return  i18n.global.t("collaborator.role.team_leader");

    default: return i18n.global.t("collaborator.role.collaborator");
  }
}


export default {

  // --- Constants ---

  TYPES,
  ROLES,
  PROJECT_ROLES,

  // --- Functions ---

  roleToCollaboratorsStatusTitle,
  roleToCompletedLabel,
  roleToText,
  roleToTaskDescription,
  roleToShortTaskDescription,
  projectRoleToText
}
