<template>
  <div class="uk-padding uk-position-cover uk-background-default">
    <div class="uk-text-center">
      <img class="documill-logo" width="64" height="64" alt="Documill logo"
           src="../../assets/img/documill-logo-32x32.svg" uk-img>
      <h1>{{ $t("service-maintenance.header") }}</h1>
      <div>
        <p class="uk-margin-remove-bottom" :key="message">
          {{ maintenanceMessage }}
        </p>
        <p v-if="hasEndDate" class="uk-text-small uk-margin-small" :key="endDate">
          {{ endDateTimeFormattedMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * UnderMaintenance page to inform the user that the service is undergoing maintenance.
 *
 * - May have a custom message. (Optional)
 * - May define the endDate in ISO format. (Optional)
 *   - If it's not defined then there is no additional message for the end of the maintenance.
 * - May have a custom dateFormat. (Optional)
 * - May have a custom timeFormat. (Optional)
 *
 * How to use:
 * <UnderMaintenance
 *   message="Documill Leap is temporarily unavailable due to planned maintenance."
 *   endDate="2024-08-30T18:00:00Z"
 *   dateFormat="dd LLLL yyyy','"
 *   timeFormat="h:mm a '('ZZZZ')'">
 * </UnderMaintenance>
 *
 * @see https://documill-intra.atlassian.net/wiki/spaces/UD/pages/25100699/Handle+Leap+maintenance#The-Maintenance-Page
 *
 * @author Documill 2024
 */
import { DateTime } from "luxon";

export default {

  name: 'UnderMaintenance',

  props: {
    message: {
      default: null,
      type: String
    },
    endDate: {
      default: null,
      type: String
    },
    dateFormat: {
      default: "dd LLLL yyyy','",
      type: String
    },
    timeFormat: {
      default: "h:mm a '('ZZZZ')'",
      type: String
    }
  },

  computed: {

    hasEndDate: function() {
      return !!this.endDate;
    },

    endDateTimeFormattedMessage: function() {
      let dateTimeObj = DateTime.fromISO(this.endDate);
      if(!dateTimeObj.isValid) {
        this.$log.warn('Invalid ISO format date-time:',this.endDate);
        return '';
      }

      let endDateTimeMessage = this.$t("service-maintenance.end-time-message.prefix");

      // Specify the formatted date or simply 'today'.
      endDateTimeMessage += dateTimeObj.hasSame(DateTime.now(),'day') ?
        this.$t("service-maintenance.end-time-message.today") :       // "today"
        this.$t("service-maintenance.end-time-message.date-prefix") + // " on "
          dateTimeObj.toFormat(this.dateFormat);

      // Specify the formatted time the maintenance ends at.
      endDateTimeMessage +=
        this.$t("service-maintenance.end-time-message.time-prefix") + // " at "
        dateTimeObj.toFormat(this.timeFormat);

      return endDateTimeMessage;
    },

    maintenanceMessage: function() {
      return this.message || this.$t("service-maintenance.message");
    }
  }
}
</script>

<style scoped>
.documill-logo {
  width: 64px;
  height: 64px;
}
</style>