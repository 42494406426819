/**
 * Collection of simple generic utility functions.
 */
import apiCall from "./api";
import { logger } from "./logger";
import Moment from "moment";
import { saveAs } from 'file-saver';
import { DateTime } from "luxon";

export default {

  /**
   * Calls .cancel() to "target" if it is not null or undefined. Useful with
   * lodash debounced/throttled functions.
   *
   * Usage:
   *
   * In component script: 1. import commons from "./../../utils/commons";
   *                      2. commons.cancel(myDebouncedFunction)
   *
   * @param {*} target debounced/throttled function
   */
  cancel(target) {
    if(target != null)
      target.cancel();
  },

  /**
   * Downloads a Document file.
   *
   * By default, it asks for the latest version.
   * But if the latest version is not available, and a revision number is given
   * by the back-end. Then try to download it again with the revision number.
   *
   * Note that since we're defining the API call with responseType:"blob",
   * the error response will also be "blob". Therefore, further processing
   * is needed to get the revisionNumber.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadFile(this, fileId, fileName, revisionNumber)
   */
  downloadFile: async function (vueInstance, fileId, fileName, revisionNumber) {
    // Start a new UI wait for a file.

    vueInstance.$store.commit("ui/ADD_BUSY_ITEM", `wait_${fileId}`);

    let url = "/v1/documents/project/download/" + fileId;

    if (!revisionNumber)
      url = url + "?latest=true";
    else
      url = url + "?revision=" + revisionNumber;

    await apiCall
      .get(url, { responseType: "blob" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // End a UI wait for a file.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${fileId}`);
      })
      .catch(async error => {
        let response = error.response;

        // The error response.data will be blob.
        // Therefore, it needs to be parsed to get the error message.
        if (error.response && error.response.status == "409") {
          try {
            const { data } = error.response;
            // Read file.
            const file = await this.fileReader(data);
            // Parse content and retrieve 'message'.
            const { message } = JSON.parse(file);
            const { revision } = JSON.parse(file);

            // If the latest version can't be downloaded, wait a bit and then
            // retry with the revision number.

            if (revision) {
              setTimeout(() => {
                this.downloadFile(vueInstance, fileId, fileName, revision);
              }, 2000);
            }
          } catch (readError) {
            // Show a dummy error if sth goes wrong while retrieving 'message'.
            logger.error("Error in downloading file.");
          }
        } else {
          logger.error("Error in downloading file.");
        }
        // End a UI wait for a file.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${fileId}`);
      });
  },

  /**
   * Downloads a specific Document Revision as a file.
   *
   * Document Revisions are snapshots or different versions of file.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadRevision(this, revisionId, fileName)
   */
  downloadRevision: async function (vueInstance, revisionId, fileName) {
    // Start a new UI wait for a file.

    vueInstance.$store.commit("ui/ADD_BUSY_ITEM", `wait_${revisionId}`);

    let url = "/v1/documents/project/download/revision/" + revisionId;

    const result = await apiCall
      .get(url, { responseType: "blob" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // End the UI wait.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${revisionId}`);
      })
      .catch(async error => {
        logger.error("Error in downloading document revision.");

        // End the UI wait.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${revisionId}`);
      });

    return result;
  },

  /**
   * Downloads a specific Document Revision as a file, with annotations rendered
   * into the document.
   *
   * This currently only works if the document is a PDF file.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadAnnotatedRevision(this, revisionId, fileName)
   */
  downloadAnnotatedRevision: async function (vueInstance, revisionId, fileName) {
    // Start a new UI wait for a file.
    vueInstance.$store.commit("ui/ADD_BUSY_ITEM", `wait_${revisionId}`);

    let url = "/v1/documents/project/download/revision/" + revisionId + "/annotated";

    const result = await apiCall
      .get(url, { responseType: "blob" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // End the UI wait.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${revisionId}`);
      })
      .catch(async error => {
        logger.error("Error in downloading annotated document revision.");

        // End the UI wait.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${revisionId}`);
      });

    return result;
  },

  /**
   * Downloads a specific Signed Document Revision as a file.
   *
   * Signed Document Revisions are E-signed versions of a specific Document
   * Revisions.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadSignedRevision(this, revisionId, fileName)
   */
  downloadSignedRevision: async function (vueInstance, revisionId, fileName) {
    // Start a new UI wait for a file.

    vueInstance.$store.commit("ui/ADD_BUSY_ITEM", `wait_${revisionId}`);

    let url = "/v1/documents/project/download/revision/signed/" + revisionId;

    await apiCall
      .get(url, { responseType: "blob" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // End the UI wait.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${revisionId}`);
      })
      .catch(async error => {
        logger.error("Error in downloading document revision.");

        // End the UI wait.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${revisionId}`);
      });
  },

  /**
   * Downloads all the files/artifacts attached to a single Step as a ZIP file. This includes:
   * - Main Anchor Document
   * - Signed version of the Document
   * - Step extra files (if any)
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadStepFilesZip(this, taskId, fileName)
   *
   * @param {*} vueInstance Vue instance
   * @param {String} taskId task identity
   * @param {String} fileName file name
   *
   * @returns {Promise} promise
   */
  downloadStepFilesZip: async function (vueInstance, taskId, fileName) {
    // Start a new UI wait for a file.

    vueInstance.$store.commit("ui/ADD_BUSY_ITEM", `wait_download_${taskId}`);

    try {
      let url = "/v1/documents/project/download/step/" + taskId + "/zip";
      const result = await apiCall.get(url, { responseType: "blob" }).then(response => {

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });

      // End the UI wait.
      vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_download_${taskId}`);
      return result;
    }
    catch(error) {
      vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_download_${taskId}`);
      throw error;
    }
  },

  /**
   * Downloads a User's private Document.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadUserDocument(this, fileId, fileName)
   */
  downloadUserDocument: async function (vueInstance, fileId, fileName) {
    // Start a new UI wait for a file.

    vueInstance.$store.commit("ui/ADD_BUSY_ITEM", `wait_${fileId}`);

    let url = '/v1/documents/user/' + fileId + '/download';

    await apiCall
      .get(url, { responseType: "blob" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // End a UI wait for a file.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${fileId}`);
      })
      .catch(async error => {
        logger.error("Error in downloading file.");

        // End a UI wait for a file.
        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", `wait_${fileId}`);
      });
  },

  /**
   * Downloads Event/Notification Log as PDF.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadLogPdf(this, url, fileName)
   */
   downloadLogPdf: async function (vueInstance, url, fileName) {

    vueInstance.$store.commit("ui/ADD_BUSY_ITEM", 'wait_downloadLog');

    await apiCall
      .get(url, { responseType: "blob" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", 'wait_downloadLog');
      })
      .catch(async error => {
        logger.error("Error in downloading project log as PDF.");

        vueInstance.$store.commit("ui/REMOVE_BUSY_ITEM", 'wait_downloadLog');
      });
  },

  /**
   * Downloads Event/Notification Log as CSV.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadLogCSV(content, fields, labels, fileName)
   *
   * @param {Object} content json object to convert to CSV
   * @param {Array} fields name of the object keys that would be included in CSV
   * @param {Object} labels object of fields/columns and translation for the column names
   * @param {String} fileName name of the downloaded file
   */
  downloadLogCSV: function (content, fields, labels, fileName) {
    const delimiter = ';';

    let data = [
      Object.values(labels).join(delimiter), //Adds the first row with column names
      ...content.map(obj => // Creates a row for each object and includes entries/keys defined in fields
          fields.reduce((acc, key) => `${acc}${!acc.length ? '' : delimiter}"${obj[key]}"`, ''))
    ].join('\n'); // Combines all rows into a string

    const blob = new Blob(["\ufeff", data], { type: 'text/csv;charset=utf-8' });

    // Use file-saver library as it supports different browsers.
    saveAs(blob, fileName, { autoBom: true });
  },


  /**
   * Downloads all revisions of the document as zip package.
   *
   * Usage:
   *
   * In component script:
   * 1. import commons from "./../../utils/commons";
   * 2. commons.downloadDocumentRevisionsZip(documentId, fileName)
   *
   * @param {String} documentId document identity
   * @param {String} fileName file name
   *
   * @see DOS-3483
   */
  downloadDocumentRevisionsZip: async function (documentId, fileName) {
    try {
      const zipFileName = this.removeFileNameSuffix(fileName) + " - all versions.zip";
      let url = "/v1/documents/project/download/" + documentId + "/zip";
      const result = await apiCall.get(url, { responseType: "blob" });

      this.createDownloadElement(result, zipFileName);
    }
    catch(error) {
      throw error;
    }
  },

  /**
   * A helper function that reads Blob error message.
   *
   * For more information see:
   * https://medium.com/@fakiolinho/handle-blobs-requests-with-axios-the-right-way-bb905bdb1c04
   */
  fileReader: function(file) {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new Error("Problem parsing file."));
      };

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.readAsText(file);
    });
  },

  /**
   * Calculates the project duration from start and end date.
   *
   * @param {String} startDate
   * @param {String} endDate
   *
   * @return {Number} duration
   */
  calculateDuration: function(startDate, endDate) {
    let startMoment =  Moment(startDate);
    let endMoment =  Moment(endDate);
    // DOS-3036: In business term: 1 day = same date
    return endMoment.diff(startMoment,"day") + 1;
  },

  /**
   * Removes the file name suffix/extension.
   *
   * @param {String} fileName file name (may be null)
   *
   * @returns {String} file name without suffix (may be null)
   */

  removeFileNameSuffix: function(fileName) {
    if(fileName == null)
      return null;

    return fileName.replace(/\.[^/.]+$/,"");
  },

  /**
   * Validates the email format.
   *
   * @param {String} email
   *
   * @return {Boolean} is the email format valid
   */
  validateEmail: function(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  /**
   * Tests if an input value is considered valid.
   *
   * Value is valid if all the following requirements are met:
   * - Trimmed value is at least one character long
   * - Trimmed value does not contain non-ASCII characters
   */
   isValidInput: function(value) {
    if(value == null)
      return false;

    // See DOS-2317 for more details.
    let trimmedValue = value.trim();
    if(trimmedValue.length < 1)
      return false;

    return this.isASCII(trimmedValue);
  },

  /**
   * Tests if the value string contains only ASCII characters between hex values 20 and FF.
   *
   * Note: Currently Leap + Sign Service do not support (all) non-ASCII characters. See DOS-2317.
   */
   isASCII: function(value) {
    return /^[\x20-\xFF]*$/.test(value);
  },

  /**
   * Helper function to create download element.
   *
   * @param {*} response Blob
   * @param {String} fileName name of the file
   */
  createDownloadElement: function(response, fileName) {
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  },

  /**
   * Tests if the service is currently under maintenance.
   *
   * The configuration file may have a scheduled maintenance period or it may simply be set to be
   * active with a truthy value.
   *
   * Note: The scheduled time may have an end date but this is only a planned date-time and can not
   *       be trusted to be true! When maintenance truly ends the configuration file needs to be
   *       changed accordingly.
   *
   * @return {boolean} true if service maintenance is under-going
   */
  isUnderMaintenance: function() {
    let config = window.FlowWebsiteGlobalConfig.serviceMaintenance;

    if(!config) // A falsy value?
      return false;

    // The maintenance is active unless a future start time is defined.

    if(!config.startDate)
      return true;

    // Test if the defined start time is not in the future.
    let startDateTimeObj = DateTime.fromISO(config.startDate);
    return !(startDateTimeObj.isValid && DateTime.now().valueOf() < startDateTimeObj.valueOf());
  }
}
