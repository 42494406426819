/**
 * Vue Store related to maintenance notifications.
 *
 * @author Documill, 2024
 */

import apiCall from '../../utils/api';
import { logger } from '../../utils/logger';

export default {

  state: {
    /** Identifiers of the currently cached notifications. */
    maintenanceNotificationIds: new Set(),
    /**
     * Currently cached notifications.
     *
     * Note: Each change to the array causes a visual effect for its relevant notification.
     * Note: To prevent visual clutter from unnecessary updates don't replace this array instance
     *       with another array instance and only remove or add objects in it if necessary.
     * Note: This array is likely to have very few items in it, if any, so it's quick to iterate.
     */
    maintenanceNotifications: []
  },

  getters: {

    getMaintenanceNotifications: state => state.maintenanceNotifications,

    getMaintenanceNotificationById: state => notificationId => {
      return state.maintenanceNotifications.find(notification => notification.id === notificationId);
    }
  },

  mutations: {

    SET_MAINTENANCE_NOTIFICATIONS: (state, notifications) => {

      // Add everything if the cache is empty.

      if(state.maintenanceNotifications.length === 0) {
        for(let notification of notifications) {
          state.maintenanceNotifications.push(notification);
          state.maintenanceNotificationIds.add(notification.id);
        }
        return;
      }

      // Or clear any cached notifications if the new array is empty.

      if(notifications.length === 0) {
        state.maintenanceNotifications.length = 0;
        state.maintenanceNotificationIds.clear();
        return;
      }

      // Otherwise update if necessary.

      let keepNotificationIds = new Set();

      for(let notification of notifications) {
        if(!state.maintenanceNotificationIds.has(notification.id)) {
          // Push only those that are not already in.
          state.maintenanceNotifications.push(notification);
          state.maintenanceNotificationIds.add(notification.id);
        }
        else {
          // Otherwise update the matching object but only if necessary.
          for(let n=0; n<state.maintenanceNotifications.length; n++) {
            let oldNotification = state.maintenanceNotifications[n];

            if(oldNotification.id === notification.id) {
              for(let field in notification) {
                // Replace the changed notification with the new one.
                // Note: Replacing the entire object triggers a visual effect.
                if(notification.hasOwnProperty(field) &&
                   notification[field] !== oldNotification[field]) {
                  state.maintenanceNotifications[n] = notification;
                  break;
                }
              }
              break;
            }
          }
        }
        keepNotificationIds.add(notification.id);
      }

      // Remove excess notifications.

      if(keepNotificationIds.size !== state.maintenanceNotificationIds.size) {
        for(let n=0; n<state.maintenanceNotifications.length; n++) {
          let notificationId = state.maintenanceNotifications[n].id;

          if(!keepNotificationIds.has(notificationId)) {
            state.maintenanceNotifications.splice(n--,1); // Note: Don't advance the index.
            state.maintenanceNotificationIds.delete(notificationId);
          }
        }
      }
    },

    REMOVE_MAINTENANCE_NOTIFICATION: (state, notificationId) => {
      // Note: Modifying the array causes a visual effect. Only removing the one item of the array
      //       only causes that effect for the one alert.
      for(let n=0; n<state.maintenanceNotifications.length; n++) {
        if(state.maintenanceNotifications[n].id === notificationId) {
          state.maintenanceNotifications.splice(n,1);
          state.maintenanceNotificationIds.delete(notificationId);
          break;
        }
      }
    }
  },

  actions: {

    async loadMaintenanceNotifications(context) {

      let notifications;

      await apiCall.get('v1/users/maintenance-notifications').then(result => {
        logger.debug("Query loadMaintenanceNotifications succeded with result:",result);

        notifications = result.data
        context.commit("SET_MAINTENANCE_NOTIFICATIONS",notifications);
      }).catch(error => {
        logger.error("Query loadMaintenanceNotifications failed with error:",error);

        throw error;
      });

      return notifications;
    },

    async closeMaintenanceNotification(context, notification) {

      await apiCall.delete('v1/users/maintenance-notifications/' + notification.id)
                   .then(result => {
        logger.debug("Query closeMaintenanceNotification succeded result:",result);

        context.commit("REMOVE_MAINTENANCE_NOTIFICATION",notification.id);
      }).catch(error => {
        logger.error("Query closeMaintenanceNotification failed with error:",error);

        throw error;
      });
    }
  }
};