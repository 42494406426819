<template>
  <div class="floating-window">
    <div
      class="content"
      draggable="true"
      @dragstart.self="startDrag($event)"
      @dragend.self="endDrag($event)">
      <div class="titlebar">
        <div class="title">{{ $t("help.quick_start.title") }}</div>
        <button type="button" class="uk-modal-close-default" uk-close v-on:click="isOpenData = false"></button>
      </div>
      <simplebar style="width: 250px; height: 260px;">
        <VTable
        v-if="formattedQuickStartList && formattedQuickStartList.length > 0"
        :data="formattedQuickStartList"
        class="uk-table uk-table-divider uk-table-hover uk-table-striped uk-table-small table-expandable"
        uk-accordion="targets: .accordion-rows; toggle: > .accordion-title; content: > .accordion-content; "
        style="margin-bottom: 0px;"
        >
        <template #body="{ rows }">
          <div v-for="row in rows" :key="row.id" class="accordion-rows">
              <tr class="accordion-title uk-table-middle">
                <td class="uk-text-nowrap uk-flex uk-flex-middle" style="font-weight:600;">
                  <div>{{row.title}}</div>
                  <div class="chevron-right" uk-icon="icon: chevron-right"></div>
                  <div class="chevron-down" uk-icon="icon: chevron-down"></div>
                </td>
              </tr>
              <tr class="accordion-content">
                <td v-on:click="openURL(row.link)">
                  <span v-html="row.content"></span>
                  <a style="weight:600">{{ $t("help.quick_start.learn_more") }}</a>
                </td>
              </tr>
          </div>
        </template>
        </VTable>
      </simplebar>
      <hr style="margin:15px -10px;">
      <div>
        <a class="uk-button" style="min-width: unset;"
        href="mailto:support@documill.com">
          <span>{{ $t("help.quick_start.contact_us") }}</span>
        </a>
        <a class="uk-button" style="min-width: unset;"
        v-on:click="openURL('https://support.documill.com/leap/index.html')">
          <span>{{ $t("help.menu.help_center") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import quickStartFile from '../../assets/quickStart.json'

export default {
  name: 'QuickStartWindow',

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      isOpenData: this.isOpen,
      quickStartList: [],
      dragX: window.innerWidth - 292,
      dragY: window.innerHeight - 400,
    }
  },

  mounted: function() {
    this.quickStartList = quickStartFile;
    $('.floating-window').css('left', this.dragX + 'px');
    $('.floating-window').css('top', this.dragY + 'px');
  },

  watch: {
    isOpen: function(newVal) {
      this.isOpenData = newVal;
    },
    isOpenData: function(newVal) {
      if(!newVal)
        this.$emit('floating-window-closed');
    },
  },

  computed: {
    formattedQuickStartList: function() {
      let self = this;
      let formatted = [];

      if(this.quickStartList) {
        // Inject translations.
        this.quickStartList.forEach((quickStartEntry) => {
          let key = quickStartEntry.localization_key;
          let title_key = "help.quick_start.entries." + key + ".title";
          let content_key = "help.quick_start.entries." + key + ".description";

          let formattedEntry = {
            "title": self.$t(title_key),
            "content": self.$t(content_key),
            "link": quickStartEntry.link,
          }
          formatted.push(formattedEntry);
        });
      }

      return formatted;
    }
  },

  methods: {
    openURL: function(url) {
      window.open(url,'_blank');
    },

    startDrag: function(event) {
      // Set drag image so that it will be positioned right at the cursor.
      this.dragX = event.offsetX;
      this.dragY = event.offsetY;
    },

    endDrag: function(event) {
      let displayData = {
        x: event.pageX + 8 - this.dragX,
        y: event.pageY - this.dragY,
      };
      $('.floating-window').css('left', displayData.x + 'px');
      $('.floating-window').css('top', displayData.y + 'px');
    },
  },
}

</script>

<style scoped>
.chevron-right,
.chevron-down {
  flex-shrink: 0;
  margin-left: auto;
}

.chevron-down,
.uk-open .chevron-right {
  display: none;
}

.uk-open .chevron-down {
  display: block;
}

.accordion-content td {
  cursor: pointer;
}
.floating-window {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px rgba(92,92,92,0.2);
  font-size: 14px;
  z-index: 1000 !important;
  position: absolute;
  cursor: move;
}

.titlebar {
  font-family: "Titillium Web", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
}

.title {
  display: flex;
  align-items: center;
}

.titlebar .button,
.titlebar button {
  cursor: pointer;
  margin: 5px;
  margin-left: auto;
}

.content {
  padding: 15px 10px !important;
}


</style>
