//FIXME: There is already another file named 'users.js' as well. Consider renaming that file e.g. to 'userUtils.js.

/**
 * Vue Store that holds data of the Users.
 *
 * @author Documill
 */

import apiCall from '../../utils/api';
import { logger } from '../../utils/logger';

export default {

  state: {
    allUsers:[],
    allUsersLoaded: false,
  },

  getters: {

    /**
     * Gets the current organization users.
     *
     * How to use:
     * this.$store.getters.getOrganizationUsers
     */
    getOrganizationUsers: (state,getters,rootState,rootGetters)=> {
      let organizationId = rootGetters.getUserOrganizationId;
      return  state.allUsers.filter(user => user.organization.id === organizationId);
    },

    /**
     * Gets all users (user can be a member of several organizations).
     *
     * How to use:
     * this.$store.getters.getAllUsers
     */
    getAllUsers: (state) => {
      return state.allUsers;
    },

    /**
     * Get user by user identity.
     *
     * How to use:
     * this.$store.getters.getUserById(userId);
     */
    getUserById: (state) => userId => {
      return state.allUsers.find(user => user.id === userId);
    },

    getAllUsersLoaded: (state) => {
      return state.allUsersLoaded;
    }


  },

  mutations: {

    /**
     * Sets all users to state.
     *
     * @param {*} state
     * @param {*} users users data object
     */

    SET_ALL_USERS(state, users) {
      state.allUsers = users;
    },

    SET_ALL_USERS_LOADED(state, value) {
      state.allUsersLoaded = value;
    },

    ADD_USERS(state, users) {
      for(let idx=0; idx<users.length;idx++)
        this.commit("ADD_USER",users[idx]);
    },

    ADD_USER(state,user) {

      for(let i = 0; i < state.allUsers.length; ++i) {
        if(state.allUsers[i].id === user.id) {
          // Insert at same position as before (update).
          state.allUsers.splice(i,1,user);
          return;
        }
      }
      state.allUsers.unshift(user);
    },

    /**
     * Assign a new group to a user.
     *
     * Expected parameters:
     *
     * params = {
     *   "userId": string,
     *   "group": {
     *      id: string,
     *      name: string
     *   }
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */
    ADD_USER_TO_GROUP(state, params) {
      let user = this.getters.getUserById(params.userId);

      if(!user) {
        logger.error("Could not find user for user id: ", params.userId);
        return;
      }
      // If a user was recently added and assigned groups are null.
      if(!user.assignedGroups)
        user.assignedGroups = [];

      let index = user.assignedGroups ? user.assignedGroups.length : 0;

      user.assignedGroups[index] = params.group;
    },

    /**
     * Delete a group assigned to user.
     *
     * Expected parameters:
     *
     * params = {
     *   "userId": string,
     *   "groupId": string
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */
    DELETE_USER_FROM_GROUP(state, params) {
      let user = this.getters.getUserById(params.userId);

      if(!user) {
        logger.error("Could not find user for user id: ", params.userId);
        return;
      }

      let index = user.assignedGroups.findIndex(group => group.id == params.groupId);

      if(index == -1) {
        logger.error("Could not find group ", groupId,
                            " to be deleted assigned to user:", userId);
        return;
      }

      user.assignedGroups.splice(index, 1);
    },

    UPDATE_USER_PRIVATE_PROJECTS_ALLOWED(state,params) {
      let user = this.getters.getUserById(params.userId);

      if(!user) {
        logger.error("Could not find user for user id: ", params.userId);
        return;
      }
      user["privateProjectsAllowed"] = params.privateProjectsAllowed;
    },

    UPDATE_USER_DEACTIVATED(state,params) {
      let user = this.getters.getUserById(params.userId);

      if(!user) {
        logger.error("Could not find user for user id: ", params.userId);
        return;
      }
      user["deactivated"] = params.deactivated;
    },

    DELETE_USER(state, userId) {
      state.allUsers = state.allUsers.filter(user => user.id !== userId);
    }
  },

  actions: {

    async loadOrganizationUsers(context) {

      let url = 'v1/users/organization';

      try {
        const result = await apiCall.get(url)
        logger.debug("Query loadOrganizationUsers succeed with message:", result);
        context.commit("ADD_USERS",result.data);
        return result;
      } catch (error) {
        logger.error("Query loadOrganizationUsers didn't succeed with message:", error);
        throw error;
      }
    },

    async loadAllUsers(context) {

      let url = 'v1/users/list';

      try {
        const result = await apiCall.get(url)
        logger.debug("Query loadAllUsers succeed with message:",result);
        context.commit("SET_ALL_USERS", result.data);
        context.commit("SET_ALL_USERS_LOADED", true);
        return result;
      }
      catch(error) {
        logger.error("Query loadAllUsers didn't succeed with message:",error);
        throw error;
      }
    },

    async addUserToGroup(context, params) {
      let url = 'v1/groups/add-user-to-group';

      let apiParams = {
        groupId: params.group.id,
        userId: params.userId
      }

      try {
        const result = await apiCall.put(url, apiParams)
        logger.debug("Add group to a user succeed:",result);
        context.commit("ADD_USER_TO_GROUP", params);
        return result;
      }
      catch(error) {
        logger.error("Add group to a user didn't succeed with message:",error);
        throw error;
      }
    },

    async deleteUserFromGroup(context, params) {
      let url = 'v1/groups/delete-user-from-group';

      try {
        const result = await apiCall.delete(url, {data: params})
        logger.debug("Delete a user from group succeed:",result);
        context.commit("DELETE_USER_FROM_GROUP", params);
        return result;
      }
      catch(error) {
        logger.error("Delete a user from group didn't succeed with message:",error);
        throw error;
      }
    },
    /**
     * Updates user property "privateProjectsAllowed".
     *
     * Expected parameters:
     *
     * params = {
     *   "userId": String,
     *   "privateProjectsAllowed": Boolean
     * }
     */

    async updateUserPrivateProjectsAllowed(context, params) {
      try {
        const result = apiCall.put('v1/users/privateProjectsAllowed', params);
        context.commit("UPDATE_USER_PRIVATE_PROJECTS_ALLOWED", params);
        return result;
      } catch (error) {
        logger.error("Updating user failed with message:", error);
        throw error;
      }
    },
    /**
     * Deactivate User
     *
     * Expected parameter: userId
     */
    async deactivateUser(context, userId) {
      try {
        const result = await apiCall.patch('/v1/users/deactivate/' + userId)
        logger.debug("Query deactivateUser succeeded with result:", result);
        context.commit("UPDATE_USER_DEACTIVATED", {
          userId: userId,
          deactivated: true
          });
        return result;
        } catch (error) {
          logger.error("Query deactivateUser didn't succeed with message:", error);
          throw error;
      }
    },

    /**
    * Reactivate User
    *
    * Expected parameter: userId
    */
    async reactivateUser(context, userId) {
      try {
        const result = await apiCall.patch('/v1/users/reactivate/' + userId)
        logger.debug("Query reactivateUser succeeded with result:", result);
        context.commit("UPDATE_USER_DEACTIVATED", {
          userId: userId,
          deactivated: false
        });
        return result;
      } catch (error) {
        logger.error("Query reactivateUser didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Delete pending invitation user
     *
     * Expected parameter: userId
     */
    async deletePendingInvitation(context, userId) {
      try {
        const result = await apiCall.delete('/v1/users/delete-pending-user/' + userId)
          logger.debug("Query deletePendingUser succeeded with result:", result);
          context.commit("DELETE_USER", userId);
          return result;
        }
        catch(error) {
          logger.error("Query deletePendingUser didn't succeed with message:", error);
          throw error;
      }
    },
  }
}
