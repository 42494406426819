<template>
  <div :uk-tooltip="tooltipEnabled ? `title:${getTooltip}; delay: 600; pos: bottom-left` : ''"
    style="flex-shrink:0;">
    <img v-if="getAvatarUrl && isVisible"
      class="documill-avatar"
      :src="getAvatarUrl" alt=""
      :style="{width: size, height: size}"/>
    <span v-else class="documill-avatar initials" :style="{width: size, height: size}">
      {{getInitials}}
    </span>
  </div>
</template>

<script>

/**
 * User Avatar.
 *
 * @author Documill
 */

import Collaborators from "./../../utils/collaborators";
import Users from "./../../utils/users";

export default {
  name: 'UserAvatar',

  // Inject the event bus in the component
  inject: ["eventBus"],

  data: function() {
    return {
      isVisible: true,
    }
  },

  // FIXME: Consider either:
  //        - Rename this component to "CollaboratorAvatar.vue".
  //        OR
  //        - Pass "user" object to this component instead of "collaborator".

  props: {
    /** Target collaborator object. */
    collaborator: {
      default: null,
      type: Object
    },

    /** To be used when displaying avatar from history entry. */
    historyEntry: {
      default: null,
      type: Object
    },

    /**
     * Custom size both for width and height (because avatar is a circle).
     * Example: "20px", "40px", etc.
     */
    size: {
      default: null,
      type: String
    },

    tooltipEnabled: {
      default: true,
      type: Boolean
    },

  },
  computed: {

    /**
     * Get the URL for the user avatar image.
     */
    getAvatarUrl: function() {
      if(this.collaborator) {
        if(this.collaborator.collaboratorType == Collaborators.TYPES.BOT)
          return "/assets/img/avatar/default-bot-avatar.svg";

        if(this.collaborator.userId)
          return Users.getAvatarUrl(this.collaborator.userId);
      }

      if(this.historyEntry && this.historyEntry.avatar)
        return this.historyEntry.avatar;

      return null
    },

    /**
     *  Get user initials in uppercase.
     */
    getInitials: function() {

      if(this.collaborator) {
        if(this.collaborator.fullName)
          return (this.collaborator.fullName.substring(0,2)).toUpperCase();

        if(this.collaborator.email)
          return this.collaborator.email.substring(0,2).toUpperCase();
      }

      if(this.historyEntry) {
        if(this.historyEntry.userName)
          return (this.historyEntry.userName.substring(0,2)).toUpperCase();

        if(this.historyEntry.userEmail)
          return this.historyEntry.userEmail.substring(0,2).toUpperCase();
      }

      return "??";
    },

    /**
     * Get tooltip shown when user hovers over the avatar.
     */
    getTooltip: function() {

      if(this.collaborator) {
        if(this.collaborator.collaboratorType == Collaborators.TYPES.BOT)
          return this.$t("collaborator.type.bot");
        if(this.collaborator.email)
          return this.collaborator.email;
      }

      if(this.historyEntry && this.historyEntry.userEmail)
        return this.historyEntry.userEmail;

      return this.$t("user.unknown_email");
    },
  },

  watch: {
    collaborator: {
       handler(val){
         // Need to hide and show the image to properly show the latest image.

         this.isVisible = false;
         this.$nextTick(() => {
           this.isVisible = true;
         });
       },
       deep: true
    }
  },

  mounted: function() {
    let self = this;

    this.eventBus.on('AVATAR_UPDATED',() => {

      // If the avatar image is updated force a refresh.
      // Note: This will cause minor flickering but it is probably not a huge problem.
      // Note: See DOS-1492.

      self.isVisible = false;
      self.$nextTick(() => {
        setTimeout(() => {
          self.isVisible = true;
        },100);
      });
    });
  },
}

</script>

<style scoped>

.initials {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  line-height: 26px;
  cursor:default;
}

</style>
