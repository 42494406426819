/**
 * Util to make the logger accessible in modules that are not components.
 *
 * @See https://github.com/MarcSchaetz/vuejs3-logger/issues/2
 */
import VueLogger from 'vuejs3-logger'
import { createApp } from 'vue'

const vuejsLoggerOptions = {
  isEnabled: true,
  logLevel: 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

// create a dummy app that can be used as logger in other js modules
const app = createApp({})
app.use(VueLogger, vuejsLoggerOptions);
const logger = app.config.globalProperties.$log;

export {VueLogger, vuejsLoggerOptions, logger};