/**
 * A collection of Local Web Storage API related functions. Use this utility instead of
 * directly calling Local Storage.
 */

// --- Constants ---

const ITEM = Object.freeze({
  FLOW_HISTORY_ENTRIES: "flow-history-entries",
  FLOW_USER_TOKEN: "flow-user-token",
  FLOW_USER_ORGANIZATIONS: "flow-user-organizations",
});

// --- Functions ---

/**
 * Removes other items from the Local Storage except "flow-history-*" keys.
 */
function clear() {
  // Note: Idea here is that this clears the other fields except "flow-history-*" keys that are
  //       used as metadata history. They need to be explicitly removed. See issue DOS-1172.

  localStorage.removeItem(ITEM.FLOW_USER_TOKEN);
  localStorage.removeItem(ITEM.FLOW_USER_ORGANIZATIONS);
}

function getHistoryEntries() {
  return localStorage.getItem(ITEM.FLOW_HISTORY_ENTRIES);
}

function getUserToken() {
  return localStorage.getItem(ITEM.FLOW_USER_TOKEN);
}

function getUserOrganizations() {
  return localStorage.getItem(ITEM.FLOW_USER_ORGANIZATIONS);
}

function removeHistoryEntries() {
  return localStorage.removeItem(ITEM.FLOW_HISTORY_ENTRIES);
}

function removeUserToken() {
  return localStorage.removeItem(ITEM.FLOW_USER_TOKEN);
}

function removeUserOrganizations() {
  return localStorage.removeItem(ITEM.FLOW_USER_ORGANIZATIONS);
}

function setHistoryEntries(entries) {
  localStorage.setItem(ITEM.FLOW_HISTORY_ENTRIES,entries);
}

function setUserToken(userToken) {
  localStorage.setItem(ITEM.FLOW_USER_TOKEN,userToken);
}

function setUserOrganizations(userOrganizations) {
  localStorage.setItem(ITEM.FLOW_USER_ORGANIZATIONS,userOrganizations);
}

function removeOrgToken(orgId) {
  let flowUser = JSON.parse(getUserOrganizations());
  flowUser.organizations =
    flowUser.organizations.filter(org => org.organizationId != orgId);
  flowUser.activeOrgId = '';

  setUserOrganizations(JSON.stringify(flowUser));
}

export default {

  // --- Functions ---

  clear,

  getHistoryEntries,
  getUserToken,
  getUserOrganizations,

  removeHistoryEntries,
  removeUserToken,
  removeUserOrganizations,
  removeOrgToken,

  setHistoryEntries,
  setUserToken,
  setUserOrganizations,
}
