<template>
  <div id="userNavBarInfo" class="uk-inline">
    <button class="uk-button uk-flex uk-flex-middle documill-user-profile" type="button">
      <div class="uk-flex uk-flex-column organization-info" style="align-items: flex-end; padding-right: 12px;">
        <div class="uk-text-bolder">{{userFullName}}</div>
        <div class="truncate-text-40ch">{{organizationName}}</div>
      </div>
      <div class="icon-avatar-dropdown">
        <UserAvatar :key="avatarComponentKey" :collaborator="userInfo" />
      </div>
    </button>
    <DropdownMenu id="user-profile-dropdown">
      <ul class="uk-list">
        <li v-on:click.stop.prevent class="uk-flex user-info-div no-pointer-events">
          <UserAvatar :key="avatarComponentKey" :collaborator="userInfo" class="dropdown-avatar"/>
          <div class="uk-flex uk-flex-column dropdown-username">
            <span class="uk-text-bolder">{{userFullName}}</span>
            <span>{{email}}</span>
          </div>
        </li>
        <hr/>
        <li v-on:click.stop.prevent class="uk-flex uk-flex-column user-info-div no-pointer-events">
          <span><span class="uk-text-bolder">{{userRoleLetterFormat}}</span> {{ $t("user_profile.user_role_in") }}</span>
          <div class="uk-flex uk-flex-column" style="line-height: 16px;">
            <span class="uk-text-bolder truncate-text-40ch" style="padding-top: 2px;">{{organizationName}}</span>
            <span class="url truncate-text-40ch" >{{host}}{{organizationPickerPath}}/{{organizationUrlName}}</span>
          </div>
        </li>
        <hr/>
        <li v-if="userProfilePage"
            v-on:click="manageProfile"
            class="unselectable-text">
          {{ $t("user_profile.manage_profile") }}
        </li>
          <li v-on:click="signInAnotherOrg"
          data-text="Sign in to other organizations"
          class="unselectable-text">
          {{ $t("user_profile.sign_in_other") }}
        </li>

        <template v-if="userOrganizations.length > 1">
          <li v-on:click.stop="switchOrgToggle = !switchOrgToggle"
            uk-toggle="target: #toggle-organizations"
            class="unselectable-text">
            <span>{{ $t("user_profile.switch_organization") }}</span>
            <span v-if="switchOrgToggle" class="uk-align-right uk-margin-remove-bottom" uk-icon="icon: triangle-up"/>
            <span v-else class="uk-align-right uk-margin-remove-bottom" uk-icon="icon: triangle-down"/>
          </li>
          <ul id="toggle-organizations" hidden class="uk-flex uk-flex-column uk-width-1-1">
            <span  v-for="org in userOrganizations" :key="org.organizationId">
              <li class="truncate-text-40ch"
                v-if="org.organizationName != organizationName"
                v-text="org.organizationName"
                v-on:click="switchOrganization(org.organizationId)"
              />
            </span>
          </ul>
        </template>
        <hr/>
        <li v-if="authenticated"
            v-on:click="userOrganizations.length > 1 ? logoutOfOrg() : logout()"
            class="uk-inline uk-width-1-1 truncate-text-40ch unselectable-text">
          <span v-text="$t('user_profile.sign_out')"/>
          <span class="uk-text-bolder">{{organizationName}}</span>
        </li>
      </ul>
    </DropdownMenu>
  </div>
</template>

<script>

import DropdownMenu from "../components/DropdownMenu";
import Users from "../../utils/users";
import UserAvatar from "./UserAvatar";
import Store from '../../store/index.js';
import LocalStorage from '../../utils/localStorage';

export default {
  name: "UserNavbarInfo",

  components: {
    DropdownMenu,
    UserAvatar
  },

  /**
   * Local variables
   */
  data: function() {
    return {
      avatarComponentKey: 0,
      // Whether Switch organization option toggled or not
      switchOrgToggle: false
    };
  },

  /**
   * Local variables that needs to be computed first
   */
  computed: {

    host: function() {
      return window.location.host;
    },

    organizationPickerPath: function() {
      return this.$router.resolve({ name: 'OrganizationPicker' }).path;
    },

    /**
     * Boolean to define if user is authenticated or not.
     * Returns true of 'Token' exist.
     */
    authenticated: function() {
      return this.$store.getters.isAuthenticated;
    },

    email: function() {
      // TODO: later on, get user information from store.
      return this.$store.getters.getUserEmail;
    },

    userInfo: function() {
      return this.$store.state.userInfo;
    },

    userRole: function() {
      return Store.getters.getUserRole;
    },

    userFullName: function() {
      return this.$store.getters.getUserFullName;
    },

    userRoleLetterFormat: function() {
      return Users.roleToText(this.userRole);
    },

    organizationName: function() {
      return this.$store.getters.getUserOrganizationName;
    },

    organizationUrlName: function() {
      return this.$store.getters.getUserOrganizationUrlName;
    },

    // disable user profile page for guests
     userProfilePage: function() {
      let isGuest = this.userInfo.userRole === 'GUEST' || this.userInfo.guestCollaboratorId;
      return !isGuest;
     },

    userOrganizations: function() {
      const userTokens = JSON.parse(LocalStorage.getUserOrganizations());
     return userTokens ? userTokens.organizations : [];
    },

  },

  /**
   * Local functions
   */
  methods: {
    /**
     * Logout user and redirect to login page.
     */
    logout() {
      this.$store.dispatch("authLogout").then( () => {
        this.$router.push({name:"login"});
      });
    },

    /**
     * Logout of the current organization and
     * redirect to login page
     */
    logoutOfOrg() {
      this.$store.dispatch("authLogoutOrg", Store.getters.organizationId).then( () => {
        this.$router.push({name:"login"});
      });
    },

    openMailTo: function(url) {
      window.location.href = url;
    },

    signInAnotherOrg() {
      LocalStorage.removeUserToken();
      Store.dispatch("resetAuthStore");
      return this.$router.replace({name: 'login'});
    },

    manageProfile() {
      this.$router.push({name:"profile"});
    },

    async switchOrganization(orgId) {
      Store.commit("ui/ADD_BUSY_ITEM","switchOrganization");
      // Update active organization value in local storage
      let userTokens = JSON.parse(LocalStorage.getUserOrganizations());
      userTokens.activeOrgId = orgId;
      LocalStorage.setUserOrganizations(JSON.stringify(userTokens));
      // Update user token in local storage
      const activeOrg = userTokens.organizations.find(org => org.organizationId === orgId);
      LocalStorage.setUserToken(activeOrg.token);

      let statuses = this.userRole == Users.ROLES.PROJECT_ADMIN ?
                      ["PLANNING", "LIVE", "PAUSED", "CANCELED", "COMPLETED"] :
                      ["LIVE"];
      Promise.all([
        // Update user information in store
        await Store.dispatch("loadUserInformation"),
        // Reset projects state;
        await Store.commit("resetProjects"),
      ])
      .then(() => {
        return this.$router.push({
                                  name: 'home',
                                  params: { organizationId: orgId }
                                })
      })
      .catch(error =>  {
        console.error("Switch organization didn't succeed: ", error);
        Store.commit("ui/REMOVE_BUSY_ITEM","switchOrganization");
        return error;
      })
    },

    /**
     * Event listener to hide User info dropdown when clicked outside
     *
     * (see comments DOS-843)
     * There is a bug in UIkit, the dropdown doesn't close, if dropdown is open
     * and a user clicks a link on a page.
     * This happens, for example, if a user clicks on a navigation tab.
     *
     */
    clickOutsideDropdown() {
      document.body.addEventListener('click', function(e) {
        let dropdown = document.getElementById('user-profile-dropdown');

        if(dropdown && dropdown.classList.contains('uk-open')
            && !document.getElementById('userNavBarInfo').contains(e.target)) {
          UIkit.dropdown('#user-profile-dropdown').hide();
        }
      })
    }
  },

  created: function(){
    this.clickOutsideDropdown();
  },

  unmounted: function() {
    document.body.removeEventListener("click", this.clickOutsideDropdown());

    // When user switches organizations the loading spinner should be displayed as long as the old
    // component is rendered. See DOS-1674.
    this.$store.commit("ui/REMOVE_BUSY_ITEM","switchOrganization");

  }
};
</script>

<style>
.uk-button.documill-user-profile {
  background-color: transparent;
  text-transform: none;
  border: none;
  color: #969fa8;
  min-width: unset;
  line-height: 16px;
}

.uk-button.documill-user-profile:hover,
.uk-button.documill-user-profile:focus,
.uk-button.documill-user-profile:focus-within,
.uk-open.uk-button.documill-user-profile {
  box-shadow: none;
}

.uk-button.documill-user-profile > .icon-avatar-dropdown {
  background: url(../../assets/img/icons/icon-avatar-dropdown-normal.png) no-repeat center right !important;
  padding-right: 12px;
}

.uk-button.documill-user-profile > .icon-avatar-dropdown:hover {
  background: url(../../assets/img/icons/icon-avatar-dropdown-hover.png) no-repeat center right !important;
}

/* Disable a triangle at the top of menu from class documill-dropdown-menu:before*/
#user-profile-dropdown:before {
  background-color: transparent;
}

#user-profile-dropdown img.documill-avatar, .dropdown-avatar {
  width: 60px !important;
  height: 60px !important;
}

.dropdown-username {
  justify-content: center;
  padding-left: 10px;
  line-height: 16px;
}

@media screen and (max-width: 640px) {
  .uk-button.documill-user-profile > .organization-info {
    display: none;
  }
}

.user-info-div {
  font-size: 0.875rem;
  padding: 10px 15px 5px 15px;
  margin: 0;
  font-weight: normal;
  white-space: nowrap;
}

.user-info-div a {
  color: #666
}

.user-info-div a:hover {
  color: #214997;
}

#toggle-organizations {
  background-color: #f2f2f2;
  margin-bottom: -5px;
  list-style: none;
}

.url{
  padding-bottom: 10px;
  padding-top: 4px;
  font-size: 12px;
}

.no-pointer-events {
  pointer-events: none;
}

</style>
