/**
 * Collection of phase related functions and constants.
 */

import i18n from './../i18n';

// --- Constants ---

/**
 * Phase status enumeration.
 *
 * Do note that this should match server-side PhaseStatus enumeration.
 */
const STATUS = Object.freeze({
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  UPCOMING: "UPCOMING"
});

/**
 * Phase type enumeration.
 *
 * Do note that this should match server-side PhaseType enumeration.
 */
const TYPES = Object.freeze({
  EDITING: "EDITING",
  EXTERNAL: "EXTERNAL",
  APPROVING: "APPROVING",
  SIGNING: "SIGNING",
  DELIVERING: "DELIVERING",
  COMBINING: "COMBINING"
});

/**
 * Phase descriptions enumeration.
 * Currently used for tooltip explanation.
 *
 * Do note that this should match server-side PhaseType enumeration.
 *
 * Note: Can't Object.freeze() these as they may change during runtime.
 * TODO: Consider moving these out of here.
 */
const DESCRIPTIONS = {
  EDITING: i18n.global.t("phase.editing.tooltip"),
  EXTERNAL: i18n.global.t("phase.external.tooltip"),
  APPROVING: i18n.global.t("phase.approving.tooltip"),
  SIGNING: i18n.global.t("phase.signing.tooltip"),
  DELIVERING: i18n.global.t("phase.delivering.tooltip"),
  COMBINING: i18n.global.t("phase.combining.tooltip")
};

/**
 * Phase type label enumeration.
 *
 * Do note that this should match server-side PhaseType enumeration.
 *
 * Note: Can't Object.freeze() these as they may change during runtime.
 * TODO: Consider moving these out of here.
 */
const LABELS = {
  EDITING: i18n.global.t("phase.editing.label"),
  EXTERNAL: i18n.global.t("phase.external.label"),
  APPROVING: i18n.global.t("phase.approving.label"),
  SIGNING: i18n.global.t("phase.signing.label"),
  DELIVERING: i18n.global.t("phase.delivering.label"),
  COMBINING: i18n.global.t("phase.combining.label")
};



export default {

  // --- Constants ---

  STATUS,
  TYPES,
  DESCRIPTIONS,
  LABELS
}
