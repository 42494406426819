/**
 * Vue Store that holds data of a project list.
 *
 * @author Documill
 */

import apiCall from './../../utils/api';
import LocalStorage from './../../utils/localStorage';
import { logger } from '../../utils/logger';
import Notification from './../../utils/notification';

export default {
  state: {
    /**
     * The data of a project list.
     *
     * How to use:
     * this.$store.state.projectList.projectList
     */
    projectList: [],
    totalProjectCount:0,
    loadingData: false,
    projectListLoaded: false,
    projectListFilter: {
      PLANNING: true,
      SCHEDULED: true,
      LIVE: true,
      PAUSED: true,
      CANCELED: false,
      COMPLETED: false
    },
    filteredProjectCount:0,
    /**
     * Axios AbortController instances. This allows cancellation of on-going API calls. This is
     * useful in situations where the actions using API are called from different places in code.
     * For example, "getUsersProjects" and "loadUserProjectsInParts" may be triggered sequentially
     * and the other may return before another, causing data to be lost.
     *
     * For more information see FLOW-3926.
     */
    abortControllers: new Set()
  },

  getters: {

    isLoadingProjectsData: state => {
      return state.loadingData;
    },

    isProjectListLoaded: state =>{
      return state.projectListLoaded;
    },

    getAbortControllers: state => {
      return state.abortControllers;
    },

    getProjectListFilter: state => {
      return state.projectListFilter;
    },

    getLiveProjectList: state => {
      return state.projectList.filter(project => project.projectStatus === 'LIVE');
    },

    /** The total project count accessible to the user.
     *
     * Not necessarily the same as state.projectlist.length, due to the filtering passed in the
     * api request to back end.
    */
    getTotalProjectCount: state => {
      return state.totalProjectCount;
    },

    getFilteredProjectCount: state => {
      return state.filteredProjectCount;
    },
  },

  mutations: {

    addAbortController(state, controller) {
      state.abortControllers.add(controller);
    },

    addProjects(state, data) {
      for(let idx=0; idx<data.length;idx++)
        this.commit("addToProjectList",data[idx]);
    },

    addToProjectList(state,projectData) {

      for(let i = 0; i < state.projectList.length; ++i) {
        if(state.projectList[i].id === projectData.id) {
          // Insert at same position as before (update).
          state.projectList.splice(i,1,projectData);
          return;
        }
      }
      state.projectList.unshift(projectData);
    },

    removeAbortController(state, controller) {
      state.abortControllers.delete(controller);
    },

    setProjectListLoaded(state, value) {
      state.projectListLoaded = value;
    },

    setProjectList(state, projects) {
      state.projectList = projects;
    },

    setLoadingData(state,value) {
      state.loadingData = value;
    },

    setTotalProjectCount(state, value){
      state.totalProjectCount = value;
    },

    setFilteredProjectCount(state, value){
      state.filteredProjectCount = value;
    },

    resetProjects(state){
      state.projectList = [];
      state.projectListLoaded = false;
      state.loadingData = false;
      state.totalProjectCount = 0;
      state.filteredProjectCount = 0;
    },

    removeFromProjectList(state,id) {
      for(var i = 0; i < state.projectList.length; ++i) {
        if(state.projectList[i].id === id) {
          state.projectList.splice(i,1);
          return;
        }
      }
    },

    /**
     * Sets project list filter by statuses to state.
     *
     * @param {*} state
     * @param {*} filter filter of project list
     */
    setProjectListFilter(state, filter) {
      state.projectListFilter = filter;
    },
  },

  actions: {
    /**
     * Aborts any currently on-going API call.
     */
    abortCurrentCalls() {
      let abortControllers = this.getters.getAbortControllers;

      abortControllers.forEach(controller => {
        controller.abort();
      });

      abortControllers.clear();
    },

    /**
     * Load list of user's projects from API based on filter of project statuses
     * and then put the data in Store.
     *
     * How to use:
     * this.$store.dispatch("getUsersProjects");
     * @param {string} userId
     */
    async getUsersProjects(context, statuses) {

      if(statuses.length==0)  // Do not make unnecessary api-call.
        return ;

      let params = {
        pageNumber: null,
        pageSize: null,
        statuses: statuses
      }

      // Abort any previously started user project data loading.

      context.dispatch("abortCurrentCalls");

      context.commit("resetProjects");
      context.commit("setLoadingData", true);

      // Set new abort controller for the call.

      const abortController = new AbortController();
      context.commit("addAbortController",abortController);

      return apiCall.post(`v1/projects/user/list`, params, { signal: abortController.signal })
        .then(result => {
        logger.debug("Query getUsersProjects succeeded with result:", result);
        context.commit("setProjectList", result.data.projects);
        context.commit("setTotalProjectCount", result.data.totalProjectCount);
        context.commit("setFilteredProjectCount", result.data.filteredProjectsCount);
        context.commit("setLoadingData",false);
        context.commit("setProjectListLoaded",true);
        return result.data;
      })
      .catch(error => {
        if(error && error.code && error.code === "ERR_CANCELED") {
          logger.debug("Query getUsersProjects was canceled:", error);
        }
        else {
          logger.error("Query getUsersProjects didn't succeed with message:", error);
          UIkit.notification("Failed to get the list of projects. <br> Try refreshing the browser.", {status:Notification.STATUS.DANGER});
        }
        context.commit("setLoadingData",false);
        context.commit("setProjectListLoaded",false);
      })
      .finally(() => {
        context.commit("removeAbortController",abortController);
      });
    },

    /**
     * Load list of user's projects in parts from API.
     *
     * This method returns already after the first subset of the projects is loaded.
     *
     * How to use:
     * this.$store.dispatch("loadUsersProjectsInParts",params);
     *
     * Parameters object:
     *
     * params = {
     *   statuses: [],
     *   router: (vue-router object)
     * }
     *
     */
    async loadUserProjectsInParts(context, params) {

      let statuses = params.statuses;

      if(statuses.length==0)  // Do not make unnecessary api-call.
        return;

      // Abort any previously started user project data loading.

      context.dispatch("abortCurrentCalls");

      // We need router here to check whether the current route changes during execution. If so,
      // we need to bail out.

      let router = params.router;

      let originalPath = router.currentRoute.path;

      let loadChunckSize = 20;
      let maxParallel = 3;

      context.commit("resetProjects");
      context.commit("setLoadingData", true);

      let apiParams = {
        pageNumber: 0,
        pageSize: loadChunckSize,
        statuses: statuses
      };

      return context.dispatch("loadUserProjectsPart", apiParams).then(result=>{

        let filteredProjectsCount = result.filteredProjectsCount;

        if(loadChunckSize >= result.filteredProjectsCount){
          context.commit("setLoadingData", false);
          context.commit("setTotalProjectCount", result.totalProjectCount);
          context.commit("setFilteredProjectCount", result.filteredProjectsCount);
          context.commit("setProjectListLoaded",true);

          return result;
        }else{
          loadRest();
          return result;
        }

        async function loadRest() {
          let pageCount = Math.ceil(filteredProjectsCount/loadChunckSize);

          try {
            let promises = [];

            // The logic here is to flush the back-end calls in small bursts to improve speed and
            // avoid unnecessary blocking. See DOS-3210.

            for(let i=1; i<=pageCount; i++)
            {
              if(!LocalStorage.getUserToken())
                return; // Do nothing. See DOS-3880.

              let params = {pageNumber: i,
                            pageSize: loadChunckSize,
                            statuses: statuses}


              promises.push(new Promise(async (resolve,reject) => {
                let errorObject = null;
                await context.dispatch("loadUserProjectsPart", params)
                  .catch((error) => {
                    errorObject = error;
                  });

                // Note: "ERR_CANCELED" may occur if "abort()" is called on abortController.

                if(errorObject != null && errorObject.code === "ERR_CANCELED")
                  return reject({type: "canceled", message: "Call canceled, abort."});
                else if(router.currentRoute.path != originalPath)
                  return reject({type: "route-change", message: "Route changed, abort."});
                else
                  return resolve();
              }));

              if(promises.length >= maxParallel) {
                await Promise.all(promises); // Execute calls.
                promises = [];
              }
            }

            if(promises.length > 0) // Execute remaining calls.
              await Promise.all(promises);
          }
          catch(error) {
            if(error && error.type === 'route-change') {
              logger.info("loadUserProjectsInParts stopped as route changed.");
            }
            else if(error && error.type === 'canceled') {
              logger.debug("Query loadUserProjectsPart was canceled.");
            }
            else {
              logger.error("loadUserProjectsInParts didn't succeed with message:", error);
              UIkit.notification("Something went wrong while loading the projects data. " +
                                "Please refresh the browser.");
            }
          }
          finally {
            context.commit("setLoadingData", false);
            context.commit("setProjectListLoaded",true);
            context.commit("setTotalProjectCount", result.totalProjectCount);
            context.commit("setFilteredProjectCount", result.filteredProjectsCount);
          }
        }
      });
    },

    /**
     *  Loads a subset of user projects.
     *
     * The subset is specified by the params.startIndex and params.endIndex, the idnecies are
     * specific to default order in projects.vue projects listing, which shows only upcoming and
     * live projects ordered by name.
     *
     * @param {*} context
     * @param {*} params
     */

    async loadUserProjectsPart(context, params) {
      // Set new abort controller for the call.

      const abortController = new AbortController();
      context.commit("addAbortController",abortController);

      let data;
      await apiCall.post(`v1/projects/user/list`,params, { signal: abortController.signal })
        .then(result => {
        logger.debug("Query loadUsersProjectsPart succeeded with result:", result);
        context.commit("addProjects", result.data.projects);
        data = result.data;
      })
      .catch(error => {
        if(error && error.code && error.code === "ERR_CANCELED")
          throw error;

        if(!LocalStorage.getUserToken())
          return; // Do nothing. See DOS-3880.

        logger.error("Query loadUsersProjectsPart didn't succeed with message:", error);
        UIkit.notification("Failed to get the list of projects. <br> Try refreshing the browser.", {status:Notification.STATUS.DANGER});
      })
      .finally(() => {
        context.commit("removeAbortController",abortController);
      });

      return data;
    },

    /**
     * Get user's project counts by statuses.
     */

    async getUserProjectsStatusCount() {

      let data;
      await apiCall.get(`v1/projects/user/countUserProjects`)
        .then(result => {
          logger.debug("Query getUserProjectsStatusCount succeeded with result:", result);
          data = result.data;
        })
        .catch(error => {
          logger.error("Query getUserProjectsStatusCount didn't succeed with message:", error);
        });
        return data;
    },

  }

}
