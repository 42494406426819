/**
 * The root JS application of Flow Service.
 *
 * @author Documill
 */

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import apiCall from './utils/api';
import SmartTable from 'vuejs-smart-table';
import VueKonva from 'vue-konva';
import VuePanZoom from 'vue-panzoom';
import { VueLogger, vuejsLoggerOptions } from './utils/logger.js';
import VueTour from 'vue3-tour';
import 'vue3-tour/dist/vue3-tour.css';
import { ability } from './config/ability';
import { abilitiesPlugin } from '@casl/vue';
import mitt from 'mitt';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import TableCellRouterLink from './components/TableCellRouterLink';
import i18n from './i18n';
import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n/";
import FeatureFlipping from 'vue-feature-flipping';
import { setEnabledFeatures } from 'vue-feature-flipping';

import "./documill-ui-library/src/stylesheets/main.css" // TODO: replace with UILIB package.

let app = createApp(App);

/* vuejs logger related constants */
const isProduction = process.env.NODE_ENV === 'production';

const eventBus = mitt();

/* DOS-1401
   If user is authenticated, but unauthorized to use Leap API,
   e.g. due to the token expiration, logout and redirect to org picker */
apiCall.interceptors.response.use(
    function (response) {
      return response;
    },
    (error) => {
      if(error.response
         && (store.getters.isAuthenticated && error.response.status === 401 || 403)
         && error.response.data.failureType == 'TOKEN_EXPIRED') {
        store.dispatch("authLogout");
        location.reload();
      }
      return Promise.reject(error);
    }
);

app.use(router);
app.use(store);
app.use(i18n);
app.use(SmartTable);
app.use(VueKonva);
app.use(VuePanZoom);
app.use(VueTour);
app.use(VueLogger, vuejsLoggerOptions);
app.use(abilitiesPlugin, ability, { useGlobalProperties: true });

// Provide EventBus to the app to be used globally
app.provide('eventBus',eventBus);

app.config.globalProperties.$apiCall = apiCall;
app.config.globalProperties.$flatpickr = flatpickr;
app.config.globalProperties.$flatpickrLanguages = flatpickrLanguages;
app.component('simplebar', simplebar);


app.component('td-router-link',TableCellRouterLink);

// DOS-1880: Override Global settings for flatpickr.
flatpickr.setDefaults({
  disableMobile: true,
});

let features = [];

// For experimental features:
// Note: Docker images usually contain production builds, including the local deployment.
//       The 'client' deployment uses a development build.

if(!isProduction) { // If not production build
  features.push("overlay-signatures"); // DOS-2979: Disable it again to further improve it.
}

features.push("overlay-annotations"); // DOS-2520: Enable overlay annotations in production builds
features.push("simple-ux"); // DOS-2845: Enable Simpler UX for production.
features.push("signature-page"); // DOS-3169 -> DOS-3440: Enable custom signature page in production.

setEnabledFeatures(features);
app.use(FeatureFlipping);

app.mount('#app')

/* :) */
/* ASCII from https://www.text-image.com/convert/ascii.html */

let documillLogo = '\n'+
'        `+dddd/ :yddhs/`\n'+
'       :ssyddd++ssshdddd+`\n'+
'          :ddd/     .odddy`\n'+
'          :ddd/       :ddds\n'+
'          :ddd/       `hddd\n'+
'```````   :ddd/       -ddds\n'+
'`/oooo+   :ddd/     `+dddh.\n'+
'  `:oo+   :dddyooosydddds`\n'+
'    `:+   :ddddddddddy+.\n'+
'      `   `--------.`\n';

console.log(documillLogo);
console.log("Documill Leap");

store.dispatch("ui/readVersion").then(() => {
  let version = store.getters['ui/getVersion'];
  console.log("Front-end version: " + version);
});
