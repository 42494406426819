/**
 * Vue Store related to current user information.
 *
 * @author Documill
 */

import apiCall from './../../utils/api';
import { logger } from '../../utils/logger';
import TimeZone from '../../utils/timeZone';
import LocalStorage from './../../utils/localStorage';
import Users from './../../utils/users';

export default {

  // TODO: Consider if we should add a namespace for this state.
  // TODO: Why these are empty strings and not e.g. null?

  state: {
    userId: '',
    email: '',
    /** User's private Documents. */
    userDocuments: [],
    userRole: '',
    userAvatar: '',
    userOrganizationId: '',
    userLocale: '',
    fullName: '',
    guestCollaboratorId: '',
    organization: '',
    assignedGroups: [],
    privateProjectsAllowed: false,
    userTimezone: '',
    appOnlyUser: false
  },

  getters: {
    /**
     * Gets current user identity.
     *
     * How to use:
     * this.$store.getters.getUserId
     */
    getUserId: state => state.userId,

    /**
     * Gets current user identity.
     *
     * How to use:
     * this.$store.getters.getUserDocuments
     */
    getUserDocuments: state => state.userDocuments,

    /**
     * Gets current user email.
     *
     * How to use:
     * this.$store.getters.getUserEmail
     */
    getUserEmail: state => state.email,

    /**
     * Gets current user full name.
     *
     * How to use:
     * this.$store.getters.getUserFullName
     */
    getUserFullName: state => state.fullName,

    /**
     * Gets currently selected locale for the user.
     *
     * How to use:
     * this.$store.getters.getUserLocale
     */
    getUserLocale: state => state.userLocale,

    /**
     * Gets current organization user role.
     *
     * How to use:
     * this.$store.getters.getUserRole
     */
    getUserRole: state => state.userRole,

    /**
     * Gets current user avatar.
     *
     * How to use:
     * this.$store.getters.getUserAvatar
     */
    getUserAvatar: state => state.userAvatar,

    /**
     * Gets one User Document from Store.
     *
     * How to use:
     * this.$store.getters.getUserDocumentById(userDocumentId)
     */
    getUserDocumentById: (state) => userDocumentId => {
      return state.userDocuments.find(document => document.id === userDocumentId);
    },

    /**
     * Gets current user organization identity.
     *
     * How to use:
     * this.$store.getters.getUserOrganizationId
     */
    getUserOrganizationId: state => state.userOrganizationId,

    /**
     * Gets current user organization name (that user is logged in).
     *
     * How to use:
     * this.$store.getters.getUserOrganizationName
     */
    getUserOrganizationName: state => state.organization.name,

    /**
     * Gets current user organization url-name (that user is logged in).
     *
     * How to use:
     * this.$store.getters.getUserOrganizationUrlName
     */
    getUserOrganizationUrlName: state => state.organization.urlName,

    /**
     * Gets current user organization "myFiles" property (that user is logged in).
     *
     * How to use:
     * this.$store.getters.isUserOrganizationMyFiles
     */
    isUserOrganizationMyFiles: state => state.organization.myFiles,

    /**
     * Gets current user identity provider id.
     *
     * How to use:
     * this.$store.getters.getUserIdentityProviderId
     */
    getUserIdentityProviderId: state => state.identityProviderId,

    /**
     * Gets current user's collaborator id, if the user is guest (user id 'guest').
     *
     * How to use:
     * this.$store.getters.getGuestCollaboratorId
     */
    getGuestCollaboratorId: state => state.guestCollaboratorId,

    /**
     * Tests whether the storages have been enabled in Organization. What this
     * means that there is at least one (1) storage integration that exists for
     * this Organization where the Project files can be stored.
     *
     * How to use:
     * this.$store.getters.isStoragesEnabled
     */
    isStoragesEnabled: state => state.organization.storagesEnabled,

    /**
     * Whether the organization's subscription has expired.
     *
     * How to use:
     * this.$store.getters.isSubscriptionExpired
     */
    isSubscriptionExpired: state => state.organization.subscriptionExpired,

    /**
     * Whether the organization's subscription includes external teams feature.
     */
    isExternalTeamsFeatureEnabled: state => state.organization.externalTeamsFeatureEnabled,

    /**
     * Gets the groups to which the user belongs.
     *
     * How to use:
     * this.$store.getters.getAssignedUserGroups
     */
    getAssignedUserGroups: state => state.assignedGroups,

    /**
     * Whether the private projects are allowed.
     */
    isPrivateProjectsAllowed: state => state.privateProjectsAllowed,

    /**
     * Gets user's timezone.
     *
     * How to use:
     * this.$store.getters.getUserTimezone
     */
    getUserTimezone: state => state.userTimezone,

    /**
     * Whether the user can only sign in with an external app (not with a username and a password).
     *
     * How to use:
     * this.$store.getters.isAppOnlyUser
     */
    isAppOnlyUser: state => state.appOnlyUser
  },

  mutations: {
    ADD_USER_DOCUMENT(state, userDocument) {
      let index = state.userDocuments ? state.userDocuments.length : 0;
      state.userDocuments[index] = userDocument;
    },

    REMOVE_USER_DOCUMENT(state, userDocumentId) {
      state.userDocuments =
          state.userDocuments.filter(document => document.id !== userDocumentId);
    },

    /**
     * Sets user private document name.
     *
     * Expected parameters:
     *
     * params: {
     *   userDocumentId: String,
     *   fileName: String
     * }
     */
    SET_USER_DOCUMENT_NAME(state, params) {
      let userDocumentId = params.userDocumentId;
      let userDocument = state.userDocuments.find(document => document.id === userDocumentId);

      if(userDocument != null)
        userDocument["fileName"] = params.fileName;
      else
        logger.error("Could not find user document: " + userDocumentId);
    },

    SET_USER_DOCUMENTS(state, userDocuments) {
      state.userDocuments = userDocuments || [];
    },

    /**
     * Sets user data to state.
     *
     * Expected parameters:
     *
     * userInfo: {
     *  id: String,
     *  email: String,
     *  role: String,
     *  avatar: String,
     *  locale: String,
     *  organizationId: String,
     *  fullName: String,
     * }
     */
    SET_USER_INFO(state, userInfo) {
      state.userId = userInfo.id;
      state.email = userInfo.email;
      state.userRole = userInfo.role;
      state.userAvatar = userInfo.avatar;
      state.userOrganizationId = userInfo.organizationId;
      state.userLocale = userInfo.locale;
      state.fullName = userInfo.fullName;
      state.organization = userInfo.organization;
      state.guestCollaboratorId = userInfo.guestCollaboratorId;
      state.identityProviderId = userInfo.identityProviderId;
      state.assignedGroups = userInfo.assignedGroups;
      state.privateProjectsAllowed = userInfo.privateProjectsAllowed;
      state.userTimezone = userInfo.timezone;
      state.appOnlyUser = userInfo.appOnlyUser;
    },

    /**
     * Resets the user information from state.
     */
    RESET_USER_INFO(state) {
      state.userId = '';
      state.email = '';
      state.userDocuments = [];
      state.userRole = '';
      state.userAvatar = '';
      state.userOrganizationId = '';
      state.userLocale = '';
      state.fullName = '';
      state.guestCollaboratorId = '';
      state.organization= '';
      state.identityProviderId= '';
      state.userTimezone = '';
    },

    SET_USER_LOCALE(state, locale) {
      state.userLocale = locale;
    },

    /**
     * Sets user name to state.
     */
    SET_USER_NAME(state, name) {
      state.fullName = name;
    },

    SET_USER_ORGANIZATION(state,organization){
      state.organization = organization;
    },

    SET_USER_ORGANIZATION_STORAGES_ENABLED(state,enabled){
      state.organization.storagesEnabled = enabled;
    },

    SET_PRIVATE_PROJECTS_ALLOWED(state,enabled){
      state.privateProjectsAllowed = enabled;
    },

    SET_USER_TIMEZONE(state, timezone) {
      state.userTimezone = timezone;
    },
  },

  actions: {

    /**
     * Attaches a User Document to a Project. This has two parts:
     * 1) Copy the User Document to a Project
     * 2) Attach the copied document to the Anchor
     *
     * Expected parameters:
     *
     * params = {
     *   "anchorId": String,
     *   "userDocumentId": String
     * }
     */

    async attachUserDocument(context,params) {
      let url = '/v1/documents/user/' + params.userDocumentId + '/attach';

      try {
        const result = await apiCall.post(url,params)
          logger.debug("Query attachUserDocument succeeded with result:", result);

          let projectDocument = result.data;

          // Update the Project state.

          context.commit("addDocument",projectDocument);

          let mutationParams = {
            anchorId: params.anchorId,
            documentId: projectDocument.id
          };

          context.commit("attachDocument", mutationParams);

          return result;
        }
        catch(error) {
          logger.error("Query attachUserDocument failed with message:", error);
          throw error;
      }
    },

    /**
     * Gets user information from back-end by calling 'whoami' API.
     *
     * Also sets the user information data to state.
     */
    getUserInfo(context) {

      return new Promise((resolve, reject) => {
        apiCall.get('v1/users/data')
          .then(result => {
            const userData = result.data;

            let userAvatar;

            if(userData.id == 'guest') {
              userAvatar = window.FlowWebsiteGlobalConfig.basePaths.flowController+
                "/v1/users/guest/avatar";
            } else {
              userAvatar = window.FlowWebsiteGlobalConfig.basePaths.flowController+
              "/v1/users/"+userData.id+"/avatar";
            }

            let userRole = userData.organizationUserRole;
            let organizationId = userData.organization.id;
            let identityProviderId = userData.identityProviderId;

            let data = {
              email: userData.email,
              role: userRole,
              organizationId: organizationId,
              id: userData.id,
              avatar: userAvatar,
              fullName: userData.fullName,
              organization: userData.organization,
              guestCollaboratorId: userData.guestCollaboratorId,
              identityProviderId: identityProviderId,
              locale: userData.locale,
              assignedGroups: userData.assignedGroups,
              privateProjectsAllowed: userData.privateProjectsAllowed,
              timezone: userData.timezone,
              appOnlyUser: userData.appOnlyUser
            };

            context.commit("SET_USER_INFO", data);
            context.commit("ORGANIZATION_SIGN_IN", organizationId);

            // Update abilities based on user role.
            Users.setOrgRoleAbilities(userData.organizationUserRole);

            // Set the system's time zone if the time zone is not defined by the user.
            if(!userData.timezone && userRole != "GUEST") {
              const timeZone = TimeZone.getUserSystemTimeZone();

              context.dispatch("updateUserTimezone",timeZone);
            }

            logger.debug("Query getUser succeeded with result:", userData);
            resolve({result: "found_user", data: data});
          })
          .catch(error => {
            if(error.response && error.response.status != 401) {
              // Unauthorized; Consider this a success with result "unauthorized"
              // Reject anyway, but make error object available for getting status
              logger.error("Query getUser didn't succeed with message:", error);
            }
            reject(error);
          });
      });
    },

    /**
     * Clears user data from local storage and state. Also clears all user
     * rights.
     */
    clearUserInfo(context) {
      // Reset state.

      context.commit("RESET_USER_INFO");

      // Reset rights/abilities.

      Users.clearUserAbilities();
    },

    /**
     * Copies the User's private Document to a Project as a Public document.
     *
     * Expected parameters:
     *
     * params = {
     *   "userDocumentId": String,
     *   "projectId": String,
     *   "fileLocation" : String
     * }
     */

    async copyUserDocumentToProject(context, params) {

      let apiParams = {
        projectId: params.projectId,
        fileLocation: params.fileLocation
      }

      let url = '/v1/documents/user/' + params.userDocumentId + '/copy-to-project';

      try {
        const result = await apiCall.post(url,apiParams)
          logger.debug("Query copyUserDocumentToProject succeeded with result:", result);

          // Add to Project state Documents, if possible.

          if(params.projectId === context.getters.getProjectId)
            context.commit("addDocument",result.data);

          return result;
        }
        catch(error) {
          logger.error("Query copyUserDocumentToProject failed with message:", error);
          throw error;
      }
    },

    /**
     * Moves the User's private Document to a Project as a Public document.
     *
     * Expected parameters:
     *
     * params = {
     *   "userDocumentId": String,
     *   "projectId": String,
     *   "fileLocation" : String
     * }
     */

    async moveUserDocumentToProject(context, params) {

      let apiParams = {
        projectId: params.projectId,
        fileLocation: params.fileLocation
      }

      let url = '/v1/documents/user/' + params.userDocumentId + '/move-to-project';

      try {
        const result = await apiCall.post(url,apiParams)
          logger.debug("Query moveUserDocumentToProject succeeded with result:", result);

          // Add to Project state Documents, if possible.

          if(params.projectId === context.getters.getProjectId)
            context.commit("addDocument",result.data);

          context.commit("REMOVE_USER_DOCUMENT", params.userDocumentId);

          return result;
        }
        catch(error) {
          logger.error("Query moveUserDocumentToProject failed with message:", error);
          throw error;
      }
    },


    /**
     * Deletes a User's private Document.
     *
     * How to use:
     * this.$store.dispatch("deleteUserDocument", userDocumentId);
     *
     * @param {String} userDocumentId user document identity
     */

    async deleteUserDocument(context, userDocumentId) {
      try {
        const result = await apiCall.delete('/v1/documents/user/' + userDocumentId)
          logger.debug("Query deleteUserDocument succeeded with result:", result);

          context.commit("REMOVE_USER_DOCUMENT",userDocumentId);

          return result;
        }
        catch(error) {
          logger.error("Query deleteUserDocument didn't succeed with message:", error);
          throw error;
      }
    },

    /**
     * Gets/Refreshes User's private Documents and saves them to state.
     *
     * How to use:
     * this.$store.dispatch("getUserDocuments");
     */
    async getUserDocuments(context) {
      let url = 'v1/documents/user';

      try {
        const result = await apiCall.get(url);
        logger.debug("Get user documents succeeded with result:", result);

        let userDocuments = await result.data;

        context.commit("SET_USER_DOCUMENTS",userDocuments);
        return result;
      }
      catch(error) {
        logger.error("Get user documents failed with message:", error);
        return error;
      }
    },

    /**
     * Get user project-specific role and define abilities to access project data.
     */
    async getUserProjectRole(context, projectId) {
      let url = 'v1/users/projectRole/' + projectId;

      try {
        const result = await apiCall.get(url);
        logger.debug("Get user project role succeeded with result:", result);
        // Set abilities based on user's project role
        let projectRole = await result.data.projectRole;
        let orgRole = this.getters.getUserRole;

        // DOS-869: The project role does not apply to Organization admins.
        // Organization admin has superuser rights
        // regardless of his/her project role.
        if(orgRole != Users.ROLES.ORGANIZATION_ADMIN) {
          Users.setProjectRoleAbilities(orgRole, projectRole);
        }

        return result;
      }
      catch(error) {
        logger.error("Get user project role didn't succeed with message:", error);
        return error.response;
      }
    },

    /**
     * Updates User's private Document name.
     *
     * Expected parameters:
     *
     * params = {
     *   "documentId" : String,
     *   "fileName" : String
     * }
     */
    async updateUserDocumentName(context, params) {

      let apiParams = {
        "fileName": params.fileName
      }

      let url = 'v1/documents/user/' + params.documentId + '/name';

      try {
        const result = await apiCall.patch(url,apiParams);
        logger.debug("User Document name change succeeded with result:",result);

        // Update document name in Vuex State. See issue DOS-2144.

        let mutatorParams = {
          fileName: params.fileName,
          userDocumentId: params.documentId
        };
        context.commit("SET_USER_DOCUMENT_NAME",mutatorParams);

        return result;
      }
      catch(error) {
        logger.error("User Document name change failed:",error);
        throw error;
      }
    },

    /**
     * Updates the user's locale.
     *
     * How to use:
     * this.$store.dispatch("updateUserLocale", locale);
     *
     * @param {String} locale locale as ISO-639-1 two letter code
     */
    async updateUserLocale(context, locale) {
      let previousLocale = context.getters.getUserLocale;

      context.commit("SET_USER_LOCALE",locale);

      let apiParams = {
        locale: locale,
        userId: this.getters.getUserId
      };

      try {
        const result = await apiCall.put('/v1/users/locale',apiParams);
        logger.debug("User locale change succeeded with result:",result);

        return result;
      }
      catch(error) {
        logger.error("User locale change failed:",error);
        context.commit("SET_USER_LOCALE",previousLocale); // Use the previous locale.

        throw error;
      }
    },

    /**
     * Updates the user's name
     *
     * How to use:
     * this.$store.dispatch("updateUserName", newName);
     * @param {string} newName
     */
    updateUserName(context, newName) {
      context.commit("SET_USER_NAME", newName);
      let data = {
        userFullName: newName,
        userId: this.getters.getUserId
      };
      apiCall.put('/v1/users/userFullName', data)
        .then(result => {
          // DOS-2473: Update user name on Sign in page.
          let historyEntries = JSON.parse(LocalStorage.getHistoryEntries() || "{}");
          const key = context.getters.getUserOrganizationName
                      + context.getters.getUserEmail
                      + context.getters.getUserIdentityProviderId;
          if(historyEntries[key]) {
            historyEntries[key].userName = newName;
            LocalStorage.setHistoryEntries(JSON.stringify(historyEntries));
          }
          logger.debug("User name is updated");
        })
        .catch(error => {
          logger.error("User name is not saved");
          logger.error(error.response);
        });
    },

     /**
     * Updates the user's time zone.
     *
     * How to use:
     * this.$store.dispatch("updateUserTimezone", timezone);
     *
     * @param {String} timezone IANA time zone.
     */
     async updateUserTimezone(context, timezone) {
      let prevTimezone = context.getters.getUserTimezone;

      context.commit("SET_USER_TIMEZONE",timezone);

      let apiParams = {
        timezoneId: timezone,
      };

      try {
        const result = await apiCall.put('/v1/users/timezone',apiParams);
        logger.debug("User timezone change succeeded with result:",result);

        return result;
      }
      catch(error) {
        logger.error("User timezone change failed:",error);
        context.commit("SET_USER_TIMEZONE",prevTimezone); // Use the previous time zone.

        throw error;
      }
    },
  },
};
