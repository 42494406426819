/**
 * Vue Store related to project collaborator autocompletion (DOS-2067).
 *
 * This is stored only locally in the current browser tab and not in the backend.
 * If this is moved to the backend, consider simply adding fields to the 'task' entity
 * and removing this store.
 *
 * This local data cannot be stored in the step itself that is stored in project.js, as
 * project auto-refresh will overwrite the step with data from the backend periodically.
 *
 * @author Documill 2022
 */

export default {

  state: {
    autopopulated: [],
  },

  getters: {
    hasAutopopulatedTasksByStepId: (state) => (stepId) => {
      return state.autopopulated.find(taskAndStep => taskAndStep.stepId == stepId) != undefined;
    }
  },

  mutations: {
    markTaskAutopopulated(state, taskAndStep) {
      // TODO: Consider avoiding duplicates.
      // TODO: The current approach might work better if we some day utilize store undoing for debugging
      state.autopopulated.push(taskAndStep);
    },

    unmarkTaskAutopopulatedByStepId(state, stepId) {
      for(let i = 0; i < state.autopopulated.length; ++i) {
        if(state.autopopulated[i].stepId == stepId) {
          state.autopopulated.splice(i,1);
          --i; // Adjust counter before ++i as we removed an element
        }
      }
    },
  },

  actions: {
    async markTaskAutopopulated(context, taskAndStep) {
      context.commit("markTaskAutopopulated",taskAndStep);
    },

    async unmarkTaskAutopopulatedByStepId(context, stepId) {
      context.commit("unmarkTaskAutopopulatedByStepId",stepId);
    },
  },

}
