// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/info-tooltip.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".docu-label {\n  display: inline-flex;\n  height: var(--sizing-wrapper-medium);\n  padding: var(--space-0);\n  align-items: center;\n  gap: var(--space-050);\n  flex-shrink: 0;\n  color: var(--color-text-primary);\n  white-space: nowrap;\n}\n\n.docu-label > .info-tooltip {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: var(--sizing-icon-xsmall);\n  height: var(--sizing-icon-xsmall);\n}\n\n.docu-label-required::before {\n  content: \"*\";\n  color: var(--color-system-error);\n}", "",{"version":3,"sources":["webpack://./src/documill-ui-library/src/stylesheets/components/label.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oCAAoC;EACpC,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,cAAc;EACd,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,gDAAmD;EACnD,gCAAgC;EAChC,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,gCAAgC;AAClC","sourcesContent":[".docu-label {\n  display: inline-flex;\n  height: var(--sizing-wrapper-medium);\n  padding: var(--space-0);\n  align-items: center;\n  gap: var(--space-050);\n  flex-shrink: 0;\n  color: var(--color-text-primary);\n  white-space: nowrap;\n}\n\n.docu-label > .info-tooltip {\n  content: url('../../assets/icons/info-tooltip.svg');\n  width: var(--sizing-icon-xsmall);\n  height: var(--sizing-icon-xsmall);\n}\n\n.docu-label-required::before {\n  content: \"*\";\n  color: var(--color-system-error);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
