/**
 * This utility class contains function to redirect from short URL to full URL
 * by token.
 *
 * @author Documill 2023
 */

import apiCall from './api';

export default {

  async redirectToNotificationUrl(notificationToken) {
    let url = 'v1/notification/' + notificationToken;

    try {
      const result = await apiCall.get(url)
      window.location.href = result.data.notificationUrl;
    }
    catch(error) {
      throw error;
    }
  },
}
