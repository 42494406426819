
<template>
  <div id="google-analytics">
  </div>
</template>

<script>

import router from "../../../router";

/**
 * Google Analytics component.
 *
 * @see DOS-1871
 *
 * @author Documill
 */

export default {
  name: "GoogleAnalytics",

  props: {
    /** If the user has consented to tracking. The component will not be loaded without consent. */
    consentGiven: {
      type: Boolean,
      required: true
    },
  },

  data: function() {
    return {
    };
  },

  components: {
  },

  mounted: function() {
    if(!this.consentGiven) {
      this.$log.warn("Cannot load Google Analytics as consent has not been given");
      return;
    }

    let analyticsId = window.FlowWebsiteGlobalConfig.analytics.google.gtagId;

    if(!analyticsId) {
      this.$log.info("Google Analytics has not been configured. Skipping Google Analytics.");
      return;
    }

    // Inject script

    // This needs to be declared also in content-security-policy-flow-website.data in Docker deployments
    let script = document.createElement("script");
    script.setAttribute("src","https://www.googletagmanager.com/gtag/js?id="+encodeURIComponent(analyticsId));

    document.head.appendChild(script);

    // Configure analytics

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    let measurementId = analyticsId; // TODO: Can there be multiple measurement ids per id in url?

    // Configure analytics not to send "page views" automatically, we send them manually below.
    // Note: This is also necessary to obfuscate page titles. See DOS-2494.

    gtag('config', measurementId, {
      send_page_view: false
    });

    // Track page changes. Google Analytics doesn't seem to recognize page changes in a single-page app otherwise.
    // See https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // and https://developers.google.com/analytics/devguides/collection/gtagjs/pages

    router.afterEach((to,from) => {
      let newPath = to.path;
      let newUrl = window.location.protocol+"//"+window.location.host+newPath;

      let meta = to.meta || {};
      let title = meta.analytics_title || to.name || "Router page name missing";

      gtag('event', 'page_view', {
        page_title: title,
        page_location: newUrl,
        page_path: newPath,
        send_to: measurementId
      });
    });
  },


  methods: {

  },
}

</script>
