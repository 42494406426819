/**
 * Collection of Organization Users related functions and constants.
 */

import i18n from './../i18n';
import {
  ability,
  defineRulesFor,
  defineProjectRulesFor
} from "../config/ability.js";

// --- Constants ---

/**
 * Organization User roles enumeration.
 *
 * Do note that this should match server-side UserOrganizationRole enumeration.
 */
const ROLES = Object.freeze({
  ORGANIZATION_ADMIN: "ORGANIZATION_ADMIN",
  PROJECT_ADMIN: "PROJECT_ADMIN",
  ORGANIZATION_MEMBER: "ORGANIZATION_MEMBER",
  GUEST: "GUEST",
  SIGN_ONLY: "SIGN_ONLY"
});

// --- Public functions ---


/**
 * Get user's avatar url.
 *
 * @param {*} userId user identity
 *
 * @return avataru URL
 */

function getAvatarUrl(userId) {
  return window.FlowWebsiteGlobalConfig.basePaths.flowController +
        "/v1/users/" + userId + "/avatar";
}

/**
 * Sets user permission rights at the organization level.
 *
 * @param {*} userOrgRole
 */
function setOrgRoleAbilities(userOrgRole) {
  let rules = defineRulesFor(userOrgRole);
  ability.update(rules);
}

/**
 * Sets user permission rights at the project level.
 *
 * Note: it also needs to set the organization's permissions
 *        to properly update the abilities.
 *
 * @param {*} userOrgRole
 * @param {*} userProjectRole
 */
function setProjectRoleAbilities(userOrgRole, userProjectRole) {
  let rules = defineRulesFor(userOrgRole)
                .concat(defineProjectRulesFor(userProjectRole));
  ability.update(rules);
}

/**
 * Deletes all user permission rights.
 */
function clearUserAbilities() {
  ability.update([]);
}

/**
 * Maps a role to a UI friendly string.
 *
 * @param role user role (may be null)
 *
 * @returns {String} UI friendly text representation of the role
 */

function roleToText(role) {
  if(role == null)
    return i18n.global.t("users.role.unknown");

  switch(role) {
    case ROLES.ORGANIZATION_ADMIN: return i18n.global.t("users.role.org_admin");
    case ROLES.PROJECT_ADMIN: return i18n.global.t("users.role.project_admin");
    case ROLES.ORGANIZATION_MEMBER: return i18n.global.t("users.role.user");
    case ROLES.GUEST: return i18n.global.t("users.role.guest");
    case ROLES.SIGN_ONLY: return i18n.global.t("users.role.sign_only");
    default:
  }

  return i18n.global.t("users.role.unknown");
}

export default {

  // --- Constants ---

  ROLES,

  // --- Functions ---
  getAvatarUrl,
  setOrgRoleAbilities,
  setProjectRoleAbilities,
  clearUserAbilities,
  roleToText
}
