/**
 * Vue Store related to Subscription plans state information.
 *
 * @author Documill 2022
 */

import apiCall from './../../utils/api';
import { logger } from '../../utils/logger';

export default {

  state: {
    subscriptionPlans: [],
  },

  getters: {

    getSubscriptionPlans: (state) => {
      return state.subscriptionPlans;
    },
  },

  mutations: {
    setSubscriptionPlans(state, plans) {
      state.subscriptionPlans = plans;
    },
  },

  actions: {
    async loadSubscriptionPlans(context) {

      let url = 'v1/subscription-plans/list';

      try {
        const result = await apiCall.get(url)
        logger.debug("Listing subscription plans succeeded:",result);
        let plans = result.data
        context.commit("setSubscriptionPlans",plans);
        return plans;
      }
      catch(error) {
        logger.error("Listing subscription plans failed:",error);

        throw error;
      }
    },

  },

}
