<template>
  <div class="switch-toggle uk-flex uk-flex-middle uk-margin"
    :class="{ 'documill-disabled' : disabled, 'uk-flex-between': buttonAtEnd}"
    @click.stop=""
    :uk-tooltip="'title:'+ tooltipText + '; pos: bottom; delay: 600;'">

    <span :uk-icon="showInfoIcon?'info':null">
    <label v-if="label" :for="name" >
      {{label}}
    </label>
    </span>

    <label class="switch">
      <input type="checkbox"
        :id="name"
        :value="value"
        :checked="checked"
        :disabled="disabled"
        @change="$emit('update:checked', $event.target.checked)"
      />
      <span class="slider" :class="{'partially-enabled': partiallyEnabled}"></span>
    </label>
  </div>
</template>

<script>

/**
 * SwitchToggle is a component that displays a boolean switch. It can be used
 * to display "checkbox"-like UI component that user can flip "on" and "off".
 *
 * Emits "change" event after the switch is flipped on or off. Event will have a single
 * parameter: boolean whether the switch is now on or off.
 *
 * How to use:
 * <SwitchToggle label="My switch"
 *   v-model:checked="myValue"
 *   name="myId" />
 *
 */
export default {
  name: 'SwitchToggle',

  props: {
    'checked': {
      type: Boolean,
      default: false
    },
    'disabled': {
      type: Boolean,
      default: false
    },
    'value': {
      type: String,
      default: null
    },
    // TODO: Consider naming this "id" as it is the element's identity.
    'name' : {
      type: String,
      required: true
    },
    'label': {
      type: String,
      default: null
    },
    'tooltip': {
      type: String,
      default: null
    },
    'tooltipDisabled': {
      type: String,
      default:null
    },
    'showInfoIcon' : {
      type:Boolean,
      default:false
    },
    // Whether the button is positioned at the end of the container.
    'buttonAtEnd' :  {
      type: Boolean,
      default: true
    },
    /* Whether to render the enabled state as "partially enabled" (i.e. with different color).
     * This can be used to signal end-user that while the toggle is enabled, there is something that
     * does not allow it to be "fully enabled". See DOS-2434 and notification preferences UI
     * specification.
     */
    'partiallyEnabled': {
      type: Boolean,
      default: false
    }
  },

  emit: ['update:checked'],

  computed: {

    tooltipText: function(){
      if(this.disabled && this.tooltipDisabled)
      {
        return this.tooltipDisabled;
      }
      else if(!this.disabled && this.tooltip) {
        return this.tooltip;
      }
       return "";
    }

  }
}
</script>

<style>

/*
* General purpose (rounded) on/off CSS slider switch.
*
* From: https://www.w3schools.com/howto/howto_css_switch.asp
*
* HTML template:
*
* <label class="switch"><input type="checkbox"><span class="slider"></span></label>
*/

label {
  cursor: pointer;
}

.switch {
  display: inline-block;
  width: 38px;
  min-width: 38px;
  height: 16px;
  margin: 0 12px 0 8px;
  position: relative;
  cursor: pointer;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 51, 102, 0.2);
  border: 1px solid #b6b6b6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:after {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: -5px;
  bottom: -5px;
  background-color: #fff;
  border: 1px solid #b6b6b6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider:hover:after {
  background-color: #f2f2f2;
}

input:checked + .slider {
  background-color: #214997;
}

input:checked + .slider.partially-enabled {
  background-color: rgba(0, 51, 102, 0.2);
}

input:checked + .slider:after {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

div.switch-toggle.documill-disabled {
  opacity: 0.5; /* TODO: Better visualization when component is disabled. */
}



</style>
