<template>
  <div>
    <div class="help-button" v-on:blur="closeDropdown">
    </div>
    <DropdownMenu id="help-dropdown-menu" class="help-menu">
      <ul class="uk-list">
        <li v-on:click="showTutorialDialog()"
        :data-text="$t('help.menu.tutorial')">
          {{ $t("help.menu.tutorial") }}
        </li>
        <li v-on:click="$emit('open-quick-start')"
        :data-text="$t('help.menu.quick_start')">
          {{ $t("help.menu.quick_start") }}
        </li>
        <li v-on:click="openURL('https://support.documill.com/leap/index.html')"
        :data-text="$t('help.menu.terms_of_service')">
          {{ $t("help.menu.help_center") }}
        </li>
        <hr>
        <li v-on:click="openURL('https://www.documill.com/terms-conditions')"
        :data-text="$t('help.menu.terms_of_service')">
          {{ $t("help.menu.terms_of_service") }}
        </li>
        <li v-on:click="openURL('https://www.documill.com/privacy-policy/gdpr-compliance-statement/')"
        :data-text="$t('help.menu.gdpr')">
          {{ $t("help.menu.gdpr") }}
        </li>
        <li v-on:click="openURL('https://www.documill.com/license-and-patent-notices/')"
        :data-text="$t('help.menu.license_notices')">
          {{ $t("help.menu.license_notices") }}
        </li>
      </ul>
    </DropdownMenu>
  </div>
</template>

<script>
import DropdownMenu from '../components/DropdownMenu';
import Notification from './../../utils/notification';

export default {
  name: "UserNavbarInfo",

  components: {
    DropdownMenu
  },

  data: function () {
    return {
      isQuickStartOpen: false
    }
  },

  methods: {
    openURL: function(url) {
      window.open(url,'_blank');
    },

    closeDropdown: function(e, helpMenuElement) {
      if(!$(e.target).hasClass("help-button")) {
        UIkit.dropdown(helpMenuElement).hide(false);
      }

    },

    showTutorialDialog: function() {
      try {
        this.$tours['tutorial'].skip();
        UIkit.modal("#tutorial-dialog").show();
      }
      catch(err) {
        UIkit.notification.closeAll();
        UIkit.notification(this.$t("help.notification.tutorial_not_available_for_page"), {status:Notification.STATUS.WARNING});
        console.log(err);
      }
    }
  },

  mounted: function() {

    let $helpMenu = $(this.$el).find(".help-menu");

    UIkit.util.on($helpMenu, 'show', () => {
      document.body.addEventListener("click", runCloseDropdown);
    });
    UIkit.util.on($helpMenu, 'hide', () => {
      document.body.removeEventListener("click", runCloseDropdown);
    });

    let self = this;
    function runCloseDropdown(e) {
      self.closeDropdown(e, $helpMenu.get(0));
    }
  }
}


</script>

<style scoped>

.help-button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-image: url(../../assets/img/icons/help.svg);
  background-position: center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

.help-button:hover,
.help-button.uk-open {
  background-image: url(../../assets/img/icons/help-hover.svg);
}

.help-menu:before {
  background-color: transparent;
}

</style>
