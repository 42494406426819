/**
 * Vue Store related to tracking editor state.
 *
 * @author Documill 2022
 */

export default {

  state: {
    openEditors: [],
  },

  getters: {
    hasOpenEditors: (state) => () => {
      return state.openEditors.length > 0;
    }
  },

  mutations: {
    recordEditorOpen(state, taskId) {
      // TODO: Consider avoiding duplicates.
      state.openEditors.push(taskId);
    },

    recordEditorClosed(state, taskId) {
      for(let i = 0; i < state.openEditors.length; ++i) {
        if(state.openEditors[i] == taskId) {
          state.openEditors.splice(i,1);
          --i; // Adjust counter before ++i as we removed an element
        }
      }
    },
  },

  actions: {
    async recordEditorOpen(context, taskId) {
      context.commit("recordEditorOpen",taskId);
    },

    async recordEditorClosed(context, taskId) {
      context.commit("recordEditorClosed",taskId);
    },
  },

}
