/**
 * Collection of Workflow related functions and constants.
 */

// --- Constants ---

/**
 * Workflow saving statuses for the UI.
 *
 * See DOS-1936.
 */
const SAVING_STATUS = Object.freeze({
  NOT_SAVED: "NOT_SAVED",
  SAVED: "SAVED",
  SAVING: "SAVING"
});

/**
 * Modes for showing Workflows in the UI.
 */
const UI_MODES = Object.freeze({
  /** Mode for editing a Workflow. */
  EDIT: "EDIT",
  /** Mode for viewing a Workflow (Workflow is not editable). */
  VIEW: "VIEW",
  /** Mode for viewing a Workflow and allowing user to select a single Step. */
  SELECT_PREVIOUS_STEP: "SELECT_PREVIOUS_STEP"
});

export default {

  // --- Constants ---

  SAVING_STATUS,
  UI_MODES
}
