/**
 * Collection of timeZone-related utility functions.
 *
 * This util uses: https://github.com/vvo/tzdb
 */

import { getTimeZones } from "@vvo/tzdb";
import i18n from "../i18n";
import { DateTime } from "luxon";

// --- Public functions ---

/**
 * Returns an array of IANA time zone objects from @vvo/tzdb.
 *
 * [
 * // ...
 *  {
 *    name: "America/Los_Angeles",
 *    alternativeName: "Pacific Time",
 *    group: ["America/Los_Angeles"],
 *    continentCode: "NA",
 *    continentName: "North America",
 *    countryName: "United States",
 *    countryCode: "US",
 *    mainCities: ["Los Angeles", "San Diego", "San Jose", "San Francisco"],
 *    rawOffsetInMinutes: -480,
 *    abbreviation: "PST",
 *    rawFormat: "-08:00 Pacific Time - Los Angeles, San Diego, San Jose, San Francisco",
 *    currentTimeOffsetInMinutes: -420, // "current" time zone offset, this is why getTimeZones() is a method and not just an object: it works at runtime
 *    currentTimeFormat: "-07:00 Pacific Time - Los Angeles, San Diego",
 *  },
 * // ...
 * ];
 *
 * @returns array of time zone objects
 */
function getIANATimeZones() {
   //TODO: Wouldn't it be better to store list in Vuex rather than pull it from a library?
  return getTimeZones();
}

/**
 * Formats a timestamp from UTC String to date object and returns time in requested
 * time zone.
 *
 * @param {String} timestamp timestamp in ISO format e.g. 2023-02-14T23:12:11.957+00:00
 * @param {String} timeZone time zone name e.g. 'America/New_York'
 * @returns time in 12-hour format e.g. 11:00 AM
 */
function formatTimestampToTimeZoneTime(timestamp, timeZone) {
  let userTimeZone = timeZone ? timeZone : getUserSystemTimeZone();

  const date = DateTime.fromISO(timestamp, { zone: `${userTimeZone}` });

  return date.setLocale('en-US').toLocaleString(DateTime.TIME_SIMPLE);
}

/**
 * Formats a timestamp from UTC String to date object and returns date in requested
 * time zone.
 *
 * @param {String} timestamp timestamp in ISO format e.g. 2023-02-14T23:12:11.957+00:00
 * @param {String} timeZone time zone e.g. 'America/New_York'
 * @returns date in format 2023-02-14
 */
function formatTimestampToTimeZoneDate(timestamp, timeZone) {
  let userTimeZone = timeZone ? timeZone : getUserSystemTimeZone();

  const date = DateTime.fromISO(timestamp, { zone: `${userTimeZone}` });

  return date.toFormat('yyyy-LL-dd');
}

/**
 * Formats a string with Date and Time to specific time zone and
 * returns ISO timestamp.
 *
 * @param {String} dateString e.g. 2023-02-14
 * @param {String} timeString e.g. 11:30
 * @param {String} timeZone time zone e.g. 'America/New_York'
 * @returns timestamp string in UTC e.g. 2023-02-14T23:12:11.957Z
 */
function formatDateStringToISOTimestamp(dateString, timeString, timeZone) {
  let userTimeZone = timeZone ? timeZone : getUserSystemTimeZone();
  const dateTimeString = dateString + " " + timeString;
  const dateObj = DateTime.fromFormat(dateTimeString, "yyyy-LL-dd h:mm a",
                                      {zone: `${userTimeZone}`, locale: 'en-US'});

  return dateObj.toUTC().toString();
}

/**
 * Returns the difference in hours and minutes between time zone and UTC.
 *
 * @param {String} timeZone IANA name of the time zone e.g. Europe/Berlin
 *
 * @returns {String} time zone difference with UTC e.g. (UTC+01:00)
 */
function offsetUTCString(timeZone) {
  let userTimeZone = timeZone ? timeZone : getUserSystemTimeZone();
  const timeZoneOffset = DateTime.local().setZone(`${userTimeZone}`).toFormat('ZZ');

  return "(UTC" + timeZoneOffset + ")";
}

/**
 * Converts time zone offset in minutes to
 * hours and minutes format.
 *
 * @param {*} offset time zone offset in minutes e.g. +60
 *
 * @returns {String} offset time in hours and minutes e.g. +01:00
 */
function formatOffsetToHoursMinutes(offset) {
  let absOffset = Math.abs(offset);
  let hours = Math.floor(absOffset / 60);
  let minutes = absOffset % 60;
  const diff = offset >=0 ? "+" : "-";
  return diff + hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0");
}


/**
 * Returns how many days remaining (or late) to a Project Deadline.
 *
 */
function getProjectRemaining(project) {
  let projectEndDate = project.plannedSchedule ? project.plannedSchedule.endDate : null;
  let projectDuration = project.plannedSchedule ? project.plannedSchedule.duration : null;

  let today = new Date();

  if (!projectEndDate && projectDuration && project.realizedSchedule) {
    let realizedStartDate = new Date(project.realizedSchedule.startDate);
    projectEndDate = realizedStartDate.setDate(realizedStartDate.getDate() + projectDuration);
  }

  if (projectEndDate) {
    const differenceMilliseconds = new Date(projectEndDate).getTime() - today.getTime();
    const dayInMilliseconds = 1000 * 3600 * 24;
    let remaining = Math.ceil(differenceMilliseconds / dayInMilliseconds);

    let absoluteRemaining = Math.abs(remaining);

    if (remaining >=0)
      return i18n.global.tc('project.deadline.days',absoluteRemaining,{days:absoluteRemaining});
    else
      return i18n.global.tc('project.deadline.days_late',absoluteRemaining,{days:absoluteRemaining});
  } else {
    return "-";
  }
}

/**
 * Returns the planned start date of a Project in string format.
 * It may also shows whether the Project was started earlier or later than
 * the planned start date.
 *
 */
function getProjectStartDateString(project, timeZone) {
  let projectStartDateString = "-";
  let projectStartDate = project.plannedSchedule ? project.plannedSchedule.startDate : null;

  if (projectStartDate) {
    projectStartDateString = formatLongDate(projectStartDate, timeZone);

    return projectStartDateString;
  }
  return projectStartDateString;
}

/**
 * Returns the planned ended date of a Project in string format.
 * It also shows whether the Project was ended earlier or later than
 * the planned end date.
 *
 */
function getProjectEndDateString(project, timeZone) {
  let projectEndDateString = "-";
  let projectEndDate = project.plannedSchedule ? project.plannedSchedule.endDate : null;

  if (projectEndDate) {
    projectEndDateString = formatLongDate(projectEndDate, timeZone);
    return projectEndDateString;
  }
  return projectEndDateString;
}

function getProjectRealizedStartDateString(project, timeZone) {
  if (project.realizedSchedule) {
    let projectRealizedStartDate = project.realizedSchedule.startDate;
    return projectRealizedStartDate ? formatLongDate(projectRealizedStartDate, timeZone) : "-";
  } else {
    return "-";
  }
}

function getProjectRealizedEndDateString(project, timeZone) {
  if (project.realizedSchedule) {
    let projectRealizedEndDate = project.realizedSchedule.endDate;

    return projectRealizedEndDate ? formatLongDate(projectRealizedEndDate, timeZone) : "-";
  } else {
    return "-";
  }
}

/**
 * Returns tooltip message for scheduled project
 * e.g. Scheduled to go Live on: March 03, 2023 at 11:00 AM (UTC+1:00)
 *
 * @param {Object} project
 * @param {String} timeZone IANA time zone name
 *
 * @returns tooltip message
 */
function getProjectScheduledTooltip(project, timeZone) {
  let projectScheduledString = "-";
  let projectStartDate = project.plannedSchedule ? project.plannedSchedule.startDate : null;

  if(projectStartDate) {
    const startDateString = getDateTimeZoneString(projectStartDate, timeZone);
    projectScheduledString = i18n.global.t("project.notification.project_scheduled_tooltip",
                                    {value: startDateString});
  }
  return projectScheduledString;
}

/**
 * Returns timestamp in format: March 03, 2023 at 11:00 AM (UTC+1:00)
 *
 * @param {String} timestamp timestamp in ISO format
 * @param {String} timeZone IANA time zone name
 *
 * @returns date with time and difference with UTC
 */
function getDateTimeZoneString(timestamp, timeZone) {
  return formatLongDate(timestamp, timeZone) + " " +
          i18n.global.t("common.preposition.at") + " " +
          formatTimestampToTimeZoneTime(timestamp, timeZone) + " " +
          offsetUTCString(timeZone);
}

/**
 * Formats a date with long format date e.g. October 04, 2020.
 *
 * @param timestamp date to be formatted (ISO timestamp)
 * @param timeZone the time zone to convert the date to
 *
 * @returns {String} date in long format October 04, 2020
 */
function formatLongDate(timestamp, timeZone) {
  let userTimeZone = timeZone ? timeZone : getUserSystemTimeZone();
  const date = DateTime.fromISO(timestamp, { zone: `${userTimeZone}` });

  return date.toFormat('LLLL dd, yyyy');
}

/**
 * Validate that the time zone name exists in tzdb.
 *
 * @param timeZone the time zone name to validate
 *
 * @returns {boolean} whether the time zone is found
 */
function isValidTimeZone(timeZoneName) {
  const timeZones = getIANATimeZones();

  return timeZones.some(zone => zone.name === timeZoneName);
}

/**
 * Get the user's default system time zone.
 *
 * @returns {String} time zone of the user's system/browser
 */
function getUserSystemTimeZone() {
  // Resolve user's system time zone
  const dt = DateTime.local();

  if(isValidTimeZone(dt.zoneName)) {
    return dt.zoneName;
  }

  // Guess timezone from offset
  const timeZones = getIANATimeZones();
  // Get the first zone that matches offset.
  const zone = timeZones.find(zone => zone.rawOffsetInMinutes === dt.offset);

  return zone.name;
}

/**
 * Validate the time string is in valid format e.g. 12:00 AM
 *
 * @param {String} time time to assign in format 11:30 PM
 *
 * @returns whether time string is valid
 */
function validateTime(time) {
  const d = DateTime.fromFormat(time, 'h:mm a');

  return d.isValid;
}

export default {
  // --- Functions ---
  getIANATimeZones,
  formatTimestampToTimeZoneTime,
  formatTimestampToTimeZoneDate,
  formatDateStringToISOTimestamp,
  formatOffsetToHoursMinutes,
  offsetUTCString,
  getProjectRemaining,
  getProjectStartDateString,
  getProjectEndDateString,
  getProjectRealizedStartDateString,
  getProjectRealizedEndDateString,
  getProjectScheduledTooltip,
  getDateTimeZoneString,
  formatLongDate,
  isValidTimeZone,
  getUserSystemTimeZone,
  validateTime
}
