<template>
  <div v-if="newVersionAvailable"
    class="version-update uk-text-center uk-card uk-padding-small uk-animation-slide-bottom">
    <span class="uk-margin-small-right">{{ $t("update_panel.description") }}</span>
    <button class="update-button uk-button uk-button-primary">
      <span class="uk-margin-small-left uk-margin-small-right" uk-icon="refresh" />
      <span class="uk-margin-small-right uk-margin-remove-left" @click="update">{{ $t("dialog.refresh") }}</span>
    </button>
  </div>
</template>

<script>

/**
 * Version poller component.
 *
 * This component constantly polls static UI resource that contains front-end version information.
 * If the version information changes during runtime, we alert end-user so that the user may refresh
 * the page. This is to avoid potential problems in situations where the front-end version changes
 * during production usage.
 *
 * For more information see issue DOS-1691 and store/modules/ui.js.
 *
 * @author Documill 2022
 */

import $ from 'jquery';

export default {
  name: 'VersionPoller',

  data: function() {
    return {
      /** Version polling identity returned by setInterval() function. */
      versionPollingId: null,
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll'],
      idle: false,
      timer: 0,
      idleTime: window.FlowWebsiteGlobalConfig.ui.idleTime,
    }
  },

  props: {
    /** Poll interval in milliseconds, defaults to 60000 (60s). */
    pollInterval: {
      type: Number,
      default: 60000
    }
  },
  computed: {
    newVersionAvailable: function() {
      return this.$store.getters['ui/isNewVersionAvailable'];
    },

    version: function() {
      return this.$store.getters['ui/getVersion'];
    }
  },

  watch: {
    version(newVersion,oldVersion) {
      if(oldVersion == null)
        return; // First time getting the version information. Bail out.

      this.$store.commit("ui/SET_NEW_VERSION_AVAILABLE");
      this.$log.debug("UI version changed from " + oldVersion + " to " + newVersion + ".");
    }
  },

  methods: {
    startVersionPolling: function() {
      if(this.versionPollingId != null)
        return; // Already polling.

      let self = this;
      this.versionPollingId = setInterval(() => {
        self.$store.dispatch("ui/readVersion");
      },this.pollInterval);
    },

    stopVersionPolling: function() {
      if(this.versionPollingId != null) {
        clearInterval(this.versionPollingId);
        this.versionPollingId = null;
      }
    },

    /**
     * Starts polling for the version information.
     */
    onActive: function() {
      if(this.idle)
      {
        this.idle = false;
        this.$store.dispatch("ui/readVersion");
        this.startVersionPolling();
      }

      clearTimeout(this.timer);
      this.timer = setTimeout(this.onIdle, this.idleTime)
    },

    /**
     * Stops polling for the version information.
     */
    onIdle: function() {
      this.idle = true;
      this.stopVersionPolling();
    },

    /**
     * "Updates" the UI version by simply reloading the page.
     */
    update: function() {
      // Add small animation to hide the panel.

      $(this.$el).addClass("uk-animation-reverse");

      setTimeout(() => {
        // After animation, do a page reload.
        // Note: The reload should also reset Vuex state so we do not need to update
        //       "newVersionAvailable" state.

        location.reload();
      },500);
    }
  },

  unmounted: function() {
    this.stopVersionPolling();
    this.events.forEach(event => {
      window.removeEventListener(event, this.onActive);
    })
  },

  mounted: function() {
    this.$store.dispatch("ui/readVersion"); // Read version now as soon as possible.
    this.startVersionPolling(); // Start version polling.
    this.events.forEach(event => {
      window.addEventListener(event, this.onActive);
    })
  },
}

</script>

<style scoped>
.version-update {
  background-color: #214997;
  border-top: 1px solid #ccc;
  box-shadow: 0px 0px 10px 2px rgb(92 92 92 / 10%);
  bottom: 0;
  color: #fff;
  font-size: 14px;
  width: 100%;
  position: fixed;
  z-index: 100;
}

.update-button {
  min-width: 120px;
}

.uk-button:hover,
.uk-button-primary:hover,
.uk-button:focus {
  color: #ccc;
  box-shadow: 0px 0px 10px 2px rgba(92,92,92,0.2);
}
</style>
