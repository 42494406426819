<template>
  <div
    :id="id"
    class="documill-dropdown-menu uk-dropdown uk-drop-close unselectable-text"
    :class="dropdownPosition"
    :uk-dropdown="dropdownOptions"
    @click.stop
  >
    <slot />
  </div>
</template>


<script>

/**
 * Simple Dropdown menu wrapper component that offers an easy way to create complex menus which can
 * be navigated using arrow keys. It is a drop-in replacement for inline element with "uk-dropdown"
 * class. The content/options are expected to be passed to the template slot.
 *
 * How to use:
 *
 * <DropdownMenu :id="myId">
 *   <ul class="uk-list">
 *     <li>Option 1</li>
 *     <li>Option 2</li>
 *   </ul>
 * </DropdownMenu>
 *
 * The button that opens the menu is the previous sibling (or another element, specified by
 * "toggle").
 *
 * The logic expects the content to be an "ul"-list where the options are list items (li-elements).
 *
 * This component also supports "submenus" where another DropdownMenu is used inside the list items
 * and user is able to enter to those submenus using arrow keys.
 *
 * See also issue DOS-2411.
 *
 * @author Documill 2022
 */

import $ from 'jquery';

export default {
  name: "DropdownMenu",

  components: {
  },

  props: {
    id: {
      type: String,
      required: true
    },
    /** Dropdown boundary selector (if any). */
    boundary: {
      type: String,
      default: null
    },
    /**
     * Hide delay for the dropdown (ms).
     *
     * Note: Many "submenu" DropdownMenus should be set to have smaller hide delay so they
     *       disappear faster when user exits the submenu.
     * Note: Too small value (for example zero) may cause submenus not work properly as they will
     *       hide too fast after opening, making them difficult to operate with a mouse pointer.
     */
    delayHide: {
      type: Number,
      default: 800
    },
    /** Dropdown mode (either "click" or "hover"). */
    mode: {
      type: String,
      default: "click"
    },
    /** Dropdown position relative to the "button" (preceding element or "toggle"). */
    pos: {
      type: String,
      default: "bottom-right"
    },
    /** Dropdown toggle target selector (if any). */
    toggle: {
      type: String,
      default: null
    },
    /** Allow dropdown to shift as needed ("true") or force its position ("false") */
    shift: {
      type: String,
      default: "true"
    },
    flip: {
      type: String,
      default: "false"
    }
  },
  data: function() {
    return {
      /** Whether a submenu is open. If so, arrow key navigation is disabled for this component. */
      inSubMenu: false,
      /** Whether the dropdown menu is open. */
      isOpen: false,
      /** Currently selected (using arrow keys) option index. */
      selectedIndex: null
    };
  },

  computed: {
    dropdownOptions: function() {
      // See UIKit documentation for the options and their valid values.
      // https://getuikit.com/docs/dropdown#component-options

      let options = [];
      options.push("mode: " + this.mode + ";");

      // Dropdown needs "flip: false" as styling "flipped" dropdown is not yet supported.
      // See DOS-2411.
      // Dropdown menu in workflow phases and steps has "flip: true" to prevent
      // it from being displayed outside the viewport. See DOS-3234
      options.push("flip: " + this.flip + ";");

      options.push("shift:" + this.shift + " ;");

      options.push("pos: " + this.pos + ";");

      options.push("delay-hide: " + this.delayHide + ";");

      if(this.toggle != null)
        options.push("toggle: " + this.toggle + ";");

      if(this.boundary != null)
        options.push("boundary: " + this.boundary + ";");

      return options.join(' ');
    },

    dropdownPosition: function() {
      // See documill.css for example ".documill-dropdown-menu.uk-dropdown-top-left:before".
      // These classes are used to render the "arrow" on the correct position.

      return "uk-dropdown-" + this.pos;
    },

    /**
     * Gets jQuery object containing all the dropdown menu items/options.
     *
     * @returns {*} dropdown menu options
     */
    options: function() {
      // Note: Do not return "disabled" options that are marked with "uk-disabled" or
      //       "no-pointer-events" classes. See DOS-3182.

      return $(this.$el).children("ul").children("li").not(".uk-disabled, .no-pointer-events");
    }
  },

  methods: {

    addKeyDownListener: function() {
      document.addEventListener("keydown", this.selectByKeyArrow, {
        capture: true,
        passive: false
      });
    },

    /**
     * Tests if the option in the "index" has a submenu.
     *
     * @param {Number} index option index
     *
     * @returns {Boolean} whether the index has a submenu
     */
    isIndexSubmenu: function(index) {
      if(index == null)
        return false;

      let $option = $(this.options.get(index));

      // The UIkit dropdown menu is the "next" sibling of the list item.
      // TODO: The dropdown menu may not be the "next" sibling if the dropdown uses "target"
      //       option. Consider handling also that use-case.

      return $option.next().hasClass("uk-dropdown");
    },

    removeKeyDownListener: function() {
      document.removeEventListener("keydown", this.selectByKeyArrow,{
        capture: true,
        passive: false
      });
    },

    /**
     * Function to select dropdown option. This is executed when user press a key down on keyboard.
     *
     * If the dropdown list is opened, the function reacts to up/down arrow keys
     * to update the selected option. The selected option can be "confirmed" by enter key, which
     * effectively performs a click to the option.
     *
     * The implementation also supports "submenus" where the list items contain also another
     * DropdownMenu. If the selected option contains "submenu", pressing right arrow key will
     * disable arrow key navigation for this particular component and let the events pass through.
     * This will effectively make the submenu "active" and end-user can navigate it with arrow keys.
     * Pressing left in submenu will make this component active again and hide the submenu.
     *
     * Note: The functionality has been tested with single level of nesting submenus. Multiple
     *       nesting submenus may require more work.
     *
     * @param {*} event keydown event
     */
    selectByKeyArrow(event) {
      let isLeftArrow = event.keyCode === 37;

      // If the focus is in submenu, do not do anything.

      if(this.inSubMenu) {
        // Pressing left arrow key in submenu effectively closes the submenu.

        if(isLeftArrow) {
          $(this.options).blur(); // Remove focus from options, will hide eventually the submenu.
          this.inSubMenu = false;

          // Try to manually hide the submenu.

          if(this.isIndexSubmenu(this.selectedIndex))
            UIkit.dropdown($(this.options.get(this.selectedIndex)).next()[0]).hide();
        }
        return; // Bail out.
      }

      let isUpArrow = event.keyCode === 38;
      let isRightArrow = event.keyCode === 39;
      let isDownArrow = event.keyCode === 40;
      let isEnter = event.keyCode === 13;

      if(this.isOpen && (isUpArrow || isDownArrow || isEnter || isRightArrow)) {
        // Prevent default action and propagation so the viewport does not scroll or wobble.

        if(isUpArrow || isDownArrow) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();

          // Remove focus and visualization from every option before we make the selection again.

          $(this.options).removeClass("active");
          $(this.options).blur(); // Remove focus from options.
        }

        if(isUpArrow) { // Up-arrow key.
          let index = this.selectedIndex != null ? this.selectedIndex - 1 : this.options.length - 1;

          if(index >= 0) {
            let $option = $(this.options.get(index));
            $option.addClass("active");
            $option.focus();

            this.selectedIndex = index;
          }
          else {
            this.selectedIndex = null;
          }
        }
        else if(isDownArrow) { // Down-arrow key.
          let index = this.selectedIndex != null ? this.selectedIndex + 1 : 0;

          if(index < this.options.length) {
            let $option = $(this.options.get(index));
            $option.addClass("active");
            $option.focus();

            this.selectedIndex = index;
          }
          else {
            this.selectedIndex = null;
          }
        }
        else if(isEnter && this.selectedIndex != null) { // Enter key.
          $(this.options.get(this.selectedIndex)).click();

          this.selectedIndex = null;
          UIkit.dropdown("#" + this.id).hide(); // Hide the dropdown menu.
        }
        else if(isRightArrow && this.isIndexSubmenu(this.selectedIndex)) { // Right arrow key. Entering submenu.
          this.inSubMenu = true;

          // Send "Down" arrow key event, this will make the first option in submenu selected,
          // signaling end-user visually that we have entered the submenu.
          // TODO: This is a bit hacky, but works.

          setTimeout(() => {
            document.dispatchEvent(
              new KeyboardEvent("keydown", {
                key: "ArrowDown",
                keyCode: 40,
                code: "ArrowDown",
                which: 40,
                shiftKey: false,
                ctrlKey: false,
                metaKey: false
              })
            );
          }, 35);
        }

        return false;
      }
      return true;
    },
  },

  mounted: function() {

    UIkit.util.on("#" + this.id, "shown", (event) => {
      event.stopPropagation();
      this.isOpen = true;

      // Add keydown event listener. This will enable arrow-key navigation and also prohibit
      // the screen from scrolling when user presses up or down.

      this.addKeyDownListener();
    });

    UIkit.util.on("#" + this.id, "hidden", (event) => {
      event.stopPropagation();
      this.isOpen = false;
      this.selectedIndex = null;
      this.inSubMenu = false;

      $(this.options).removeClass("active"); // Remove the "active" class from options.

      // Remove the keydown event listener when the dropdown menu is hidden.

      this.removeKeyDownListener();
    });

    UIkit.util.on("#" + this.id, "show", (event) => {
      event.stopPropagation();
    });

    UIkit.util.on("#" + this.id, "hide", (event) => {
      event.stopPropagation();
    });
  },

  unmounted() {
    // Remove the keydown event listener when the component is unmounted.

    this.removeKeyDownListener();
  }
};
</script>

<style scoped>

</style>
