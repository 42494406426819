/**
 * Collection of notification related constants.
 */

// --- Constants ---

/**
 * Style of notification
 *
 * This is based on the Notification Styles in UIKit
 * https://getuikit.com/docs/notification#style
 *
 * How to use:
 * - import first:
 * import Notification from 'notification';
 * - use it in UIkit.notification:
 * UIkit.notification(message, {status:Notification.STATUS.PRIMARY});
 *
 */
const STATUS = Object.freeze({
  PRIMARY: "primary",
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger"
});

export default {
  // --- Constants ---
  STATUS

  // --- Functions ---

}
