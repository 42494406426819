const dateTimeFormats = {
  en: {
    date_time: {
      year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true
    },
    short_date: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long_date: {
      year: 'numeric', month: 'long', day: 'numeric'
    }
  },
  fi: {
    date_time: {
      year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
    },
    short_date: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long_date: {
      year: 'numeric', month: 'long', day: 'numeric'
    }
  }
};
export default dateTimeFormats;