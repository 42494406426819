/**
 * Collection of Project related functions and constants.
 */

import i18n from './../i18n';

// --- Constants ---

const STATUS = Object.freeze({
  CANCELED: "CANCELED",
  COMPLETED: "COMPLETED",
  LIVE: "LIVE",
  PAUSED: "PAUSED",
  PLANNING: "PLANNING",
  SCHEDULED: "SCHEDULED",
});

// --- Functions ---

/**
 * Gets the default name for a copied Project. Usually it is something like
 * "Copy of <originalName>".
 *
 * @param {String} originalName
 */
 function getDefaultCopyName(originalName) {
  return i18n.global.t("project.copy_project_name",{projectName: originalName});
}

/**
 * Gets the default name for a Project.
 *
 * @param {Boolean} isTemplate whether the project is a template project
 *
 * @returns project default name
 */

function getDefaultName(isTemplate) {
  if(isTemplate === true)
    return i18n.global.t("project_workflow.untitled_workflow");

  return i18n.global.t("project.untitled_project");
}

/**
 * Maps a Project status to a UI friendly string.
 *
 * @param status project status (may be null)
 *
 * @returns {String} UI friendly text representation of the status
 */

 function statusToText(status) {
  if(status == null)
    return "";

  switch(status) {
    case STATUS.PLANNING: return i18n.global.t("project.status.planning");
    case STATUS.LIVE: return i18n.global.t("project.status.live");
    case STATUS.COMPLETED: return i18n.global.t("project.status.completed");
    case STATUS.PAUSED: return i18n.global.t("project.status.paused");
    case STATUS.CANCELED: return i18n.global.t("project.status.canceled");
    case STATUS.SCHEDULED: return i18n.global.t("project.status.scheduled");
    default: return "";
  }
}

export default {
  // --- Constants ---

  STATUS,

  // --- Functions ---

  getDefaultCopyName,
  getDefaultName,
  statusToText
}
