/**
 * Collection of step related functions and constants.
 */

import i18n from './../i18n';
import Collaborators from './collaborators';

// --- Constants ---

/**
 * Step status enumeration.
 *
 * Do note that this should match server-side StepStatus enumeration.
 */
const STATUS = Object.freeze({
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  UPCOMING: "UPCOMING"
});

/**
 * Step type enumeration.
 *
 * Do note that this should match server-side StepGoal enumeration.
 */
const TYPES = Object.freeze({
  APPROVE: "APPROVE",
  DELIVER: "DELIVER",
  EDIT: "EDIT",
  EXTERNAL_EDIT: "EXTERNAL_EDIT",
  EXTERNAL_REVIEW: "EXTERNAL_REVIEW",
  REVIEW: "REVIEW",
  SIGN: "SIGN",
  SIGN_CONFIGURE: "SIGN_CONFIGURE",
  COMBINE: "COMBINE",
  OFFLINE_EDIT: "OFFLINE_EDIT",
  VALIDATION: "VALIDATION"
});

/**
 * Enumeration of the tabs in Step properties dialog.
 */
const DIALOG_TABS = Object.freeze({
  COLLABORATOR: "Collaborator",
  FILES: "Files",
});


// --- Functions ---

/**
 * Gets an array of Step's extra files (including anchor document if so specified).
 *
 * The returned array is sorted by the natural order of extra files. If anchorDocument
 * parameter is omitted then the anchor document is not included in the return array.
 *
 * @param {*} step
 * @param {*} $store Vuex store
 * @param {*} anchorDocument anchor document (may be null)
 * @param {*} templateDocuments template documents (may be null). In Workflows tab there is no
 * project in state so we use documents from props. See DOS-3523.
 */
function getStepExtraFiles(step,$store,anchorDocument,templateDocuments) {
  let sortedExtraDocuments = sortExtraFiles(step.extraDocuments);

  let documents = [];

  let addExtraDocument = (extraDocumentWrapper) => {
    let documentId = extraDocumentWrapper.documentId;
    if(!this.anchorDocument || this.anchorDocument.id != documentId) {
      let document
      if(templateDocuments)
        document = templateDocuments.find(document => document.id === documentId);
      else
        document = $store.getters.getDocumentByID(documentId);

      documents.push(document);
    }
  };

  sortedExtraDocuments.beforeMainDocument.forEach(addExtraDocument);

  if(anchorDocument != null)
    documents.push(anchorDocument);

  sortedExtraDocuments.afterMainDocument.forEach(addExtraDocument);

  return documents;
}

/**
 * Gets the default collaborator/task role for a specific step type.
 *
 * @param stepType step type (see #TYPES)
 * @param isOrgUser whether the collaborator is organization user (has a user identity).
 *
 * @returns default collaborator/task role
 */
function getDefaultRoleForStepType(stepType, isOrgUser) {
  switch(stepType) {
    case TYPES.APPROVE: return Collaborators.ROLES.APPROVER;
    case TYPES.DELIVER: return Collaborators.ROLES.INTERNAL_READER;
    case TYPES.REVIEW: return Collaborators.ROLES.REVIEWER;
    case TYPES.SIGN: return Collaborators.ROLES.SIGNER;
    case TYPES.SIGN_CONFIGURE: return Collaborators.ROLES.SIGNING_CONFIGURATOR;
    case TYPES.EXTERNAL_EDIT: return Collaborators.ROLES.EXTERNAL_EDITOR;
    case TYPES.EXTERNAL_REVIEW: return Collaborators.ROLES.EXTERNAL_REVIEWER;
    case TYPES.COMBINE: return Collaborators.ROLES.COMBINER;
    case TYPES.OFFLINE_EDIT: return Collaborators.ROLES.OFFLINE_EDITOR;
    case TYPES.VALIDATION: return Collaborators.ROLES.VALIDATION_REVIEWER;
    case TYPES.EDIT:
      return isOrgUser ? Collaborators.ROLES.APPROVING_EDITOR : Collaborators.ROLES.EDITOR;
    default:
      return Collaborators.ROLES.APPROVING_EDITOR;
  }
}


/** Gets the step type that matches the role type.
 *
 * @param {*} taskRole
 * @param stepType matching step type
 */

function taskRoleToStepType (taskRole) {

  switch(taskRole) {
    case Collaborators.ROLES.APPROVER:
      return TYPES.APPROVE;
    //case Collaborators.ROLES.EXTERNAL_READER:
    case Collaborators.ROLES.INTERNAL_READER:
      return TYPES.DELIVER;

    case Collaborators.ROLES.REVIEWER:
       return TYPES.REVIEW;
    case Collaborators.ROLES.SIGNER:
      return TYPES.SIGN;
    case Collaborators.ROLES.SIGNING_CONFIGURATOR:
      return TYPES.SIGN_CONFIGURE;
    case Collaborators.ROLES.APPROVING_EDITOR:
    case Collaborators.ROLES.COMMENTER:
    case Collaborators.ROLES.EDITOR:
      return TYPES.EDIT;
    case Collaborators.ROLES.OFFLINE_EDITOR:
      return TYPES.OFFLINE_EDIT;
    case Collaborators.ROLES.EXTERNAL_EDITOR:
      return TYPES.EXTERNAL_EDIT;
    case Collaborators.ROLES.EXTERNAL_REVIEWER:
      return TYPES.EXTERNAL_REVIEW;
    case Collaborators.ROLES.COMBINER:
      return TYPES.COMBINE;
    case Collaborators.ROLES.VALIDATION_REVIEWER:
      return TYPES.VALIDATION;
    default:
       return null;
  }
}

function sortExtraFiles(stepExtraFiles) {
  let sorted = [].concat(stepExtraFiles).sort((a,b) => {
    // Sort by sort offset as main criterion, and document id as the tie breaker if something has gone wrong
    // This should match StepService.recomputeStepExtraDocumentOffsets(Step, String, String)
    if(a.sortOffsetToMainDocument < b.sortOffsetToMainDocument)
      return -1;
    if(a.sortOffsetToMainDocument > b.sortOffsetToMainDocument)
      return 1;
    if(a.sortOffsetToMainDocument == b.sortOffsetToMainDocument) {
      if(a.documentId == b.documentId)
        return 0;
      return a.documentId < b.documentId ? -1 : 1;
    }
    throw new Error("Expected to never end up here");
  });

  // The main document is not included in this list, so return entries
  // that should appear before main document, and after main document, separately.

  let beforeMainDocument = sorted.filter((a) => a.sortOffsetToMainDocument < 0);
  let afterMainDocument = sorted.filter((a) => a.sortOffsetToMainDocument >= 0);

  return {
    beforeMainDocument,
    afterMainDocument
  };
}

function stepTypeToText(stepType) {

  switch(stepType) {
    case TYPES.APPROVE: return i18n.global.t("step.approve");
    case TYPES.DELIVER: return i18n.global.t("step.deliver");
    case TYPES.REVIEW: return i18n.global.t("step.review");
    case TYPES.SIGN: return i18n.global.t("step.sign");
    case TYPES.SIGN_CONFIGURE: return i18n.global.t("step.sign_configure");
    case TYPES.EXTERNAL_EDIT: return i18n.global.t("step.external_edit");
    case TYPES.EXTERNAL_REVIEW: return i18n.global.t("step.external_review");
    case TYPES.COMBINE: return i18n.global.t("step.combine");
    case TYPES.EDIT: return i18n.global.t("step.edit");
    case TYPES.OFFLINE_EDIT: return i18n.global.t("step.offline_edit");
    case TYPES.VALIDATION: return i18n.global.t("step.validation");
    default:
      return "";
  }
}

export default {

  // --- Constants ---

  STATUS,
  TYPES,
  DIALOG_TABS,

  // --- Functions ---

  getDefaultRoleForStepType,
  getStepExtraFiles,
  taskRoleToStepType,
  sortExtraFiles,
  stepTypeToText,
}
